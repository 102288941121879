<!-- eslint-disable max-len -->

<template>
  <div class="pt-5">
    <!-- Hero Section-->
    <section class="hero pt-3">
      <div class="container">
        <!-- Breadcrumbs -->
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Login</li>
        </ol>
        <!-- Hero Content-->
        <div class="hero-content pb-5 text-center">
          <h1 class="hero-heading mb-0">Login</h1>
        </div>
      </div>
    </section>
    <!-- customer login-->
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="block">
              <div class="block-header">
                <h6 class="text-uppercase mb-0">Welcome!</h6>
              </div>
              <div class="block-body">
                <p class="lead">Not registered yet? <router-link to="/register">Register here</router-link></p>
                <hr>
                <!-- <p v-if="message.error" class="text-white px-3 py-2 text-uppercase bg-danger mb-2"> <small>{{message.error}}</small> </p>
                <p v-if="message.success" class="text-white px-3 py-2 text-uppercase bg-success mb-2"> <small>{{message.success}}</small> </p> -->
                <transition name="fade">
                  <p v-if="message.error" class="px-3 py-2 alert alert-danger mb-3">{{message.error}}</p>
                </transition>
                <transition name="fade">
                  <p v-if="message.success" class="px-3 py-2 alert alert-success mb-3">{{message.success}}</p>
                </transition>
                <form @submit.prevent="loginUser">
                  <div class="form-group">
                    <label for="email" class="form-label">Email<span>*</span></label>
                    <input v-model="email_l" type="email" class="form-control" :class="message.error?'border-primary':''" placeholder="" required>
                  </div>
                  <div class="form-group">
                    <label for="password" class="form-label">Password<span>*</span></label>
                    <input v-model="password_l" type="password" class="form-control" :class="message.error?'border-primary':''" placeholder="" required>
                  </div>
                  <div class="form-group text-center">
                    <button type="submit" class="btn btn-outline-dark" :disabled="loader">
                      <div class="d-flex justify-content-center align-items-center">
                        <i v-if="!loader" class="fa fa-sign-in-alt mr-2"></i>
                        <span>Log in</span>
                        <span v-if="loader" class="ml-2 spinner-grow spinner-grow-sm" role="status"></span>
                        <span v-if="loader" class="sr-only">Loading...</span>
                        <!-- <div v-if="loader" class="ml-2 spinner-grow spinner-grow-sm text-info d-flex justify-content-center" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <div v-if="loader" class="ml-2 spinner-grow spinner-grow-sm text-success d-flex justify-content-center" role="status">
                          <span class="sr-only">Loading...</span>
                        </div> -->
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
// import firebase from 'firebase/app';
// import 'firebase/auth';

export default {
  name: 'Login',
  data: () => ({
    email_l: '',
    password_l: '',
    loader: false,
    message: {
      error: '',
      success: '',
    },
  }),
  computed: {
    ...mapGetters(['surl']),
  },
  methods: {
    ...mapActions(['setUserRole']),
    redirectUser(account) {
      this.$store.dispatch('setUserRole');
      if (account === 'Couple') this.$router.replace('/couple/profile');
      // if (account === 'Couple') window.location = '/couple/profile';
      if (account === 'Guest') this.$router.push('/guest/profile');
      if (account === 'Vendor') this.$router.push('/vendor/profile');
    },
    clearLoginFormData() {
      this.username = '';
      this.account = '';
      this.email_r = '';
      this.password_r = '';
    },
    setMessageTimer() {
      setTimeout(() => {
        this.message.success = '';
        this.message.error = '';
      }, 10000);
    },
    async loginUser(e) {
      e.preventDefault();
      this.message.error = '';
      this.loader = true;
      // firebase.auth().signInWithEmailAndPassword(this.username_l, this.password_l)
      //   .then((u) => {
      //     console.log(u.user);
      //     console.log(`You are logged in as ${u.user.email}`);
      //     this.$router.push('/bride-n-groom');
      //   }).catch((err) => {
      //     if (err.message === 'The password is invalid or the user does not have a password.') {
      //       this.error = 'Your password is invalid. Please try again.';
      //     } else {
      //       this.error = err;
      //     }
      //   });
      try {
        axios.post(`${this.surl}/api/auth/users/login`, {
          email: this.email_l,
          password: this.password_l,
        })
          .then((response) => {
            this.message.error = '';
            // this.loader = false;
            // console.log(response.data);
            const {
              account, token, id, role,
            } = response.data;

            localStorage.setItem('_ikn', token);
            localStorage.setItem('_acc', account);
            localStorage.setItem('_ud', id);
            localStorage.setItem('_rlc', role.couple);
            localStorage.setItem('_rlg', role.guest);
            localStorage.setItem('_rlv', role.vendor);
            // console.log(account);
            // this.$store.dispatch('setUserRole');
            // this.message.success = response.data.message;
            this.clearLoginFormData();
            this.redirectUser(account);
            // this.setMessageTimer();
          })
          .catch((err) => {
            if (err.response.status >= 400) {
              this.message.success = '';
              console.log(err);
            }
            this.message.success = '';
            this.loader = false;
            // console.log(err.message);
            // console.log(err.response);
            if (err.message) this.message.error = err.message;
            if (err.response) this.message.error = err.response.data;
            if (err.response.data.message) this.message.error = err.response.data.message;
          });
      } catch (error) {
        if (error) this.message.success = '';
        // this.message.error = err.response.data.message;
      }
    },
  },
  updated() {
    // this.setMessageTimer();
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to, .fade-leave-active {
  opacity: 0;
}
</style>
