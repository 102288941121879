<template>
  <!-- eslint-disable max-len -->
  <div class="search-area-wrapper" :class="searchArea ? 'search-open' : 'search-close'">
    <div class="search-area d-flex align-items-center justify-content-center">
      <div class="close-btn" @click="closeSearch">
        <svg class="svg-icon svg-icon-light w-3rem h-3rem" viewBox="0 0 64 64"><path data-name="layer1" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M41.999 20.002l-22 22m22 0L20 20" stroke-linejoin="round" stroke-linecap="round"/></svg>
      </div>
      <form action="#" class="search-area-form">
        <div class="form-group position-relative">
          <input type="search" name="search" id="search" placeholder="Search iWedhub ..." class="search-area-input">
          <button type="submit" class="search-area-button">
            <svg class="svg-icon" viewBox="0 0 64 64"><path data-name="layer2" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M39.049 39.049L56 56" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer1" cx="27" cy="27" r="17" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FullScreenSearchArea',
  data: () => ({
  }),
  methods: {
    closeSearch() {
      this.$store.commit('setSearch', false);
    },
  },
  computed: {
    ...mapGetters(['searchArea']),
  },
};
</script>

<style scoped>
.search-open {
  display: block;
}
.search-close {
  display: none;
}
</style>
