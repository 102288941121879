<!-- eslint-disable max-len -->

<template>
  <div class="pt-5">
    <section class="hero">
      <div class="container pt-3">
        <!-- Breadcrumbs -->
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Contact        </li>
        </ol>
        <!-- Hero Content-->
        <div class="hero-content pb-5 text-center">
          <h1 class="hero-heading">Contact Us</h1>
          <div class="row">
            <div class="col-xl-8 offset-xl-2"><p class="lead text-muted">Have concerns or questions?
              Do not hesitate to contact us via email, phone call or social media. Check out our
              <a href="/faqs">F.A.Q section</a>.
            </p></div>
          </div>
        </div>
      </div>
    </section>
    <section style="background: #fafafa;" class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-md-4 text-center text-md-left">
            <svg viewBox="0 0 64 64" class="svg-icon svg-icon-light text-primary w-3rem h-3rem mb-3"><path d="M24.4 32.8L20 33.9l-18-6v28L20 62l24-6.1 18 5v-27l-12-4m-30 4V62m24-24v17.9" stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" data-name="layer2" stroke-linejoin="round" stroke-linecap="round"/><path stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" d="M32 2a14 14 0 00-14 14c0 12.6 14 26 14 26s14-13.4 14-26A14 14 0 0032 2z" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/><circle stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" r="4" cy="16" cx="32" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/></svg>
            <h4 class="ff-base">Address</h4>
            <p class="text-muted">HQ - Kampala, <strong>Uganda</strong></p>
          </div>
          <div class="col-md-4 text-center text-md-left">
            <svg class="svg-icon svg-icon-light text-primary w-3rem h-3rem mb-3" viewBox="0 0 64 64"><path data-name="layer2" d="M32 62a30 30 0 1128.9-22M32 62c15.5 0 22.8-9.3 16.7-15.4" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer1" d="M49.1 40c.8.5 2.1 1.3 1.8 3a8.6 8.6 0 01-8.1 6c-5.4 0-12-4.2-17.8-10.1S15 26.5 15 21.1a8.6 8.6 0 016-8.1c1.7-.2 2.4 1 3 1.8l4.1 6.2a2.9 2.9 0 01-.8 3.9c-1.7 1.4-3.6 2.2 2.9 8.7s7.3 4.7 8.7 2.9a2.9 2.9 0 013.9-.8z" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>
            <h4 class="ff-base">Call Center</h4>
            <p class="text-muted">Our customer care center are available 24/7. We are more than delighted to hear from you.</p>
            <p class="text-muted"><strong>{{iwh_contact}}</strong></p>
          </div>
          <div class="col-md-4 text-center text-md-left">
            <svg class="svg-icon svg-icon-light text-primary w-3rem h-3rem mb-3" viewBox="0 0 64 64"><path data-name="layer2" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M2 12l30 29 30-29M42 31.6L62 52M2 52l20-20.4" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer1" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M2 12h60v40H2z" stroke-linejoin="round" stroke-linecap="round"/></svg>
            <h4 class="ff-base">Electronic Support</h4>
            <p class="text-muted">Please feel free to email to us for support or more information.</p>
            <ul class="list-unstyled text-muted">
              <li>hello@iwedhub.com</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="py-6">
      <div class="container">
        <header class="mb-5">
          <h2 class="text-uppercase h5">Contact form</h2>
        </header>
        <div class="row">
          <div class="col-md-7 mb-5 mb-md-0">
            <form @submit.prevent="contactUs" class="form">
              <div class="controls">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="name" class="form-label">Your firstname *</label>
                      <input v-model="user.firstname" type="text" name="name" placeholder="Enter your firstname" required class="form-control">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="lastname" class="form-label">Your lastname *</label>
                      <input v-model="user.lastname" type="text" name="lastname" placeholder="Enter your  lastname" required class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" class="form-label">Your email *</label>
                  <input v-model="user.contactEmail" type="email" name="email" required placeholder="Enter your  email" class="form-control">
                </div>
                <div class="form-group">
                  <label for="message" class="form-label">Your message for us *</label>
                  <textarea v-model="user.message" placeholder="Enter your message" required class="form-control"></textarea>
                </div>
                <button type="submit" class="btn" :class="sendBtnText==='send message'?'btn-outline-dark':'btn-outline-info'">{{sendBtnText}}</button>
              </div>
            </form>
          </div>
          <div class="col-md-5">
            <p class="text-muted"> We are here to make your wedding preparations smooth and fun. </p>
            <p class="text-muted"> Follow us on social media. </p>
            <div class="social">
              <ul class="list-inline">
                <li class="list-inline-item"><a href="https://www.twitter.com/iwedhub" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li class="list-inline-item"><a href="https://www.facebook.com/iwedhub" target="_blank"><i class="fab fa-facebook"></i></a></li>
                <li class="list-inline-item"><a href="https://www.instagram.com/iwedhub" target="_blank"><i class="fab fa-instagram"></i></a></li>
                <li class="list-inline-item"><a href="https://www.pinterest.com/iwedhub" target="_blank"><i class="fab fa-pinterest"></i></a></li>
                <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCZ06_ZuVMvJDgcbtlHkQg2Q" target="_blank"><i class="fab fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'Contact',
  data() {
    return {
      sendBtnText: 'send message',
      user: {
        firstname: '',
        lastname: '',
        contactEmail: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['iwh_contact', 'surl']),
  },
  methods: {
    contactUs() {
      // console.log(this.user);
      this.sendBtnText = 'sending message...';
      const {
        firstname, lastname, contactEmail, message,
      } = this.user;
      axios.post(`${this.surl}/api/users/contact-us/`, {
        firstname, lastname, contactEmail, message,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response.data);
          this.sendBtnText = 'send message';
          /* eslint-disable no-alert */
          this.user.firstname = '';
          this.user.lastname = '';
          this.user.contactEmail = '';
          this.user.message = '';
          alert(response.data.message);
        })
        .catch((err) => {
          console.log(err.response.data);
          this.sendBtnText = 'send message';
        });
    },
  },
};
</script>

<style scoped>
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
