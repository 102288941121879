<template>
  <!-- eslint-disable max-len -->
  <div class="card mb-5 shadow-sm">
    <table class="table table-borderless table-hover table-responsive-md">
      <thead class="bg-light">
        <tr>
          <!-- <th class="text-uppercase text-sm"></th> -->
          <th class="text-uppercase text-sm">Ref</th>
          <th class="text-uppercase text-sm">Name</th>
          <th class="text-uppercase text-sm">Item/Offering</th>
          <th class="text-uppercase text-sm">Amount</th>
          <th class="text-uppercase text-sm">Date</th>
          <!-- <th class="text-uppercase text-sm">Status</th> -->
          <!-- <th class="text-uppercase text-sm"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p,i) in PaymentsReceived" :key="i">
          <!-- <th class="align-middle"><img :src="p.couple_img_url" class="budget-img"/></th> -->
          <td class="align-middle">#00{{i+1}}</td>
          <td class="align-middle">{{p.couple}}</td>
          <td class="align-middle">{{p.item}}</td>
          <td class="align-middle">${{p.amount_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td>
          <td class="align-middle">{{p.date}}</td>
          <!-- <td class="align-middle"><span class="badge text-uppercase p-2" :class="p.amount_paid/p.price>0 ? (p.amount_paid/p.price>0.5 ? (p.amount_paid/p.price === 1 ? 'badge-success' : 'badge-info') : 'badge-warning'): 'badge-danger'">{{(p.amount_paid*100/p.price).toFixed(0)}}% Paid</span></td> -->
          <!-- <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm shadow-sm">View</a></td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PaymentsReceivedComponent',
  props: {
  },
  computed: {
    ...mapState(['PaymentsReceived']),
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
