<!-- eslint-disable max-len -->
<template>
  <div>
    <!-- <section class="mh-full-screen dark-overlay py-7 d-flex align-items-center justify-content-center" data-parallax="scroll" :data-image-src="require(`../assets/bb1.jpg`)">
      <div class="overlay-content w-100">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 text-white">
              <h5 class="text-uppercase text-white letter-spacing-5 mb-3 "> About us</h5>
              <h1 class="display-3 font-weight-bold text-shadow mb-5">iWedhub</h1>
              <h6 class="text-uppercase text-white letter-spacing-5 mb-3"> Vision </h6>
              <p class="text-lg">Realise marriage celebrations effortlessly.</p>
              <h6 class="text-uppercase text-white letter-spacing-5 mb-3"> Mission </h6>
              <p class="text-lg">Empower engaged/married couples to effortlessly organize and realise their dream weddings, honeymoons, getaways, and anniversaries online. </p>
              <h6 class="text-uppercase text-white letter-spacing-5 mb-3"> How? </h6>
              <p class="text-lg justify-content-center">Mobilize funds from loved ones, book vendors and honeymoon experiences, issue e-invites and live stream your dream wedding seamlessly with our web and mobile app.</p>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 about-text-column bg-gray-100">
            <div class="about-text">
              <h6 class="text-uppercase text-muted letter-spacing-5 mb-4">Wedding Launches</h6>
              <h2 class="mb-4">You both said Yes?</h2>
              <p class="text-lg text-muted">Create your own socialmedia shareable wedding launch card/page and let your loved ones celebrate this great news with you.</p>
            </div>
          </div>
          <div class="col-lg-6 p-lg-0 about-image-column" data-parallax="scroll" :data-image-src="require(`../assets/yes.jpg`)"></div>
        </div>
        <div class="row">
          <div class="col-lg-6 about-text-column order-lg-2">
            <div class="about-text">
              <h6 class="text-uppercase text-muted letter-spacing-5 mb-4">Budget & Gifts</h6>
              <h2 class="mb-4">Manage Vendors</h2>
              <p class="text-lg text-muted"> Receive gifts or financial contributions from family, friends and colleagues in one place. You can use the funds to book vendors in your budget or honeymoon experiences.</p>
            </div>
          </div>
          <div class="col-lg-6 p-lg-0 about-image-column order-lg-1 dark-overlay" data-parallax="scroll" :data-image-src="require(`../assets/wedprep10.jpg`)"></div>
        </div>
        <div class="row">
          <div class="col-lg-6 about-text-column bg-gray-100">
            <div class="about-text">
              <h6 class="text-uppercase text-muted letter-spacing-5 mb-4">Guest e-Invites</h6>
              <h2 class="mb-4">Invite Guests Electronically</h2>
              <p class="text-lg text-muted">Create unique electronic invite cards and invite your loved ones to celebrate this memorable day with you.</p>
            </div>
          </div>
          <div class="col-lg-6 p-lg-0 about-image-column" data-parallax="scroll" :data-image-src="require(`../assets/wedprep2.jpg`)"></div>
        </div>
      </div>
    </section>
    <!-- <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h6 class="text-uppercase text-muted letter-spacing-5 mb-4">Livestream</h6>
            <h2 class="mb-4"> Online Attendance </h2>
            <p class="text-lg text-muted"> Do you have guests who can't not make it for your wedding?
            iWedhub gives guests the option to attend online via livestream. </p>
          </div>
          <div class="col-lg-6">
            <h6 class="text-uppercase text-muted letter-spacing-5 mb-4">Honeymoon</h6>
            <h2 class="mb-4"> Honeymoon Experiences</h2>
            <p class="text-lg text-muted"> Check out your favorite honeymoon spots and book unforgettable experiences that will last a life time. </p>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: 'vendor-page',
};
</script>
