<template>
  <!-- eslint-disable max-len -->
  <div class="card mb-5 shadow-sm">
    <table class="table table-borderless table-hover table-responsive-md">
      <thead class="bg-light">
        <tr>
          <th class="text-uppercase text-sm">Ref</th>
          <th class="text-uppercase text-sm">Name</th>
          <th class="text-uppercase text-sm">Amount</th>
          <!-- <th class="text-uppercase text-sm text-center">Status</th> -->
          <th class="text-uppercase text-sm">Date</th>
          <th class="text-uppercase text-sm">Contact</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c,i) in user.contributions_received" :key="i">
          <td class="align-middle">#00{{i+1}}</td>
          <td class="align-middle">{{c.contributor.full_name}}
            <router-link v-if="c.contributor.username&&c.contributor.account" :to="`/${c.contributor.account.toLowerCase()}s/${c.contributor.username}`" class="text-info text-sm">(@{{c.contributor.username}})</router-link>
            <span v-if="c.contributor.username&&!c.contributor.account" class="text-info text-sm">(@{{c.contributor.username}})</span>
            <em v-if="!c.contributor.username" class="text-muted text-sm">(no @username)</em>
          </td>
          <td class="align-middle">{{c.amount.currency.split('- ')[1]}} {{c.amount.given.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td>
          <!-- <td class="align-middle text-center"><span class="badge text-uppercase p-1" :class="c.status === 'Pledge' ? 'badge-warning' : (c.status === 'Cash' ? 'badge-info' : 'badge-success')">{{c.status}}</span></td> -->
          <td class="align-middle">{{c.date.split('T')[0]}}</td>
          <td class="align-middle">{{c.contributor.email}}, {{c.contributor.country.name}} {{c.contributor.phone}}</td>
        </tr>
      </tbody>
      <tbody v-if="user.contributions_received.length === 0">
        <td class="align-middle text-muted">No contributions received yet</td>
      </tbody>
      <tfoot v-if="user.contributions_received !== 0">
        <tr>
          <td class="align-middle text-uppercase text-sm"><strong>Total</strong></td>
          <td></td>
          <td class="align-middle"><strong>{{ttl}}</strong></td>
          <td></td>
          <td></td>
          <!-- <td></td> -->
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
/* eslint-disable max-len */
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ContributionsReceivedComponent',
  props: {
  },
  data() {
    return {
      country: '',
      currency: '',
      ttl: '',
    };
  },
  computed: {
    ...mapState(['ContributionsReceived', 'Countries']),
    ...mapGetters(['user']),
    total() {
      let t = 0;
      const currency = [];
      this.user.contributions_received.forEach((c) => {
        t += c.amount.given;
        currency.push(c.amount.currency);
      });
      return `${currency[0].split('- ')[1]} ${t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
  },
  methods: {
    getCC() {
      this.country = this.user.address.country;
      /* eslint-disable prefer-destructuring */
      this.currency = this.$store.getters.Currency.split('- ')[1];
      console.log(this.country, this.currency);
    },
    getTotal() {
      let t = 0;
      const crs = this.user.contributions_received;
      crs.forEach((cr) => {
        const fromC = cr.amount.currency.split('- ')[1].toLowerCase();
        const toC = this.currency.toLowerCase();
        axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${fromC}/${toC}.json`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            // console.log(response.data);
            const rate = response.data[toC];
            // console.log(cr.amount.given * rate);
            t += (cr.amount.given * rate);
            // console.log(t);
            this.ttl = `${this.currency} ${t.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            // console.log({ total_contributions_received: this.ttl });
          })
          .catch((err) => {
            console.log(err.response);
          });
      });
    },
  },
  created() {
    this.getCC();
    this.getTotal();
  },
  mounted() {
    // this.getCC();
    // this.getTotal();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
