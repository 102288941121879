<!-- eslint-disable max-len -->

<template>
  <div class="px-5 mt-6">
    <div class="hero-content text-center mb-4 pt-5">
      <h3 class="hero-heading">Settings</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: 'settings',
  data: () => ({

  }),
};
</script>
<style scoped>
</style>
