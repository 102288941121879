<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-4">
      <h3 class="hero-heading">Contributions Received</h3>
    </div>
    <ContributionsReceivedComponent/>
  </div>
</template>

<script>
import ContributionsReceivedComponent from '../ContributionsReceivedComponent.vue';

export default {
  name: 'ContributionsReceived',
  components: { ContributionsReceivedComponent },
  props: {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
