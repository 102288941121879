<template>
<!-- eslint-disable max-len -->
<div>
  <header class="product-grid-header">
  <div class="mr-3 mb-3">
    Showing <strong>1-3 </strong>of <strong>{{UpcomingWeddings.length}}</strong> Upcoming
  </div>
  <div class="mr-3 mb-3"><span class="mr-2">Show</span><a href="#" class="product-grid-header-show active">12    </a><a href="#" class="product-grid-header-show ">24    </a><a href="#" class="product-grid-header-show ">All    </a>
  </div>
  <div class="mb-3 d-flex align-items-center"><span class="d-inline-block mr-1">Sort by</span>
    <select class="custom-select w-auto border-0">
      <option value="orderby_0">Default</option>
      <option value="orderby_1">Oldest first</option>
      <option value="orderby_2">A-Z</option>
      <option value="orderby_3">Newest first</option>
    </select>
  </div>
  </header>
  <div class="row">
    <!-- couples -->
    <div class="col-xl-2 col-sm-6" v-for="(u, i) in UpcomingWeddings" :key="i">
      <div class="card product border-edges">
        <div class="product-image">
          <img :src="u.couple_img_url" :alt="u.couple" class="upcoming-card" />
          <div class="product-hover-overlay">
            <a href="#" class="product-hover-overlay-link"></a>
            <div class="product-hover-overlay-buttons">
              <a href="#" class="btn btn-dark btn-buy"><span class="btn-buy-label ml-2">View</span></a>
            </div>
          </div>
        </div>
        <div class="card-body text-center text-sm p-1">
          <p class="text-muted mb-1">{{u.message}}</p>
          <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">{{u.couple}}</a></h3><span class="text-muted">{{u.date}}</span>
        </div>
      </div>
    </div>
    <!-- /couples -->
    <div v-if="UpcomingWeddings.length === 0">
      <p class="text-muted text-center">Not upcoming weddings yet!</p>
    </div>
  </div>
  <nav aria-label="page navigation" class="d-flex justify-content-center mb-5 mt-3">
  <ul class="pagination">
    <li class="page-item"><a href="#" aria-label="Previous" class="page-link"><span aria-hidden="true">Prev</span><span class="sr-only">Previous</span></a></li>
    <li class="page-item active"><a href="#" class="page-link">1       </a></li>
    <li class="page-item"><a href="#" class="page-link">2       </a></li>
    <li class="page-item"><a href="#" aria-label="Next" class="page-link"><span aria-hidden="true">Next</span><span class="sr-only">Next     </span></a></li>
  </ul>
  </nav>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RegistryComponent',
  props: [],
  data: () => ({
  }),
  computed: {
    ...mapState(['UpcomingWeddings']),
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
