<template>
  <!-- eslint-disable max-len -->
  <div class="header-iwh">
    <header class="header header-absolute">
      <!-- Top Bar-->
      <top-bar />
      <!-- Navbar-->
      <nav-bar />
      <!-- Fullscreen search area-->
      <full-screen-search-area />
    </header>
    <!-- Sign Modal -->
    <sign-modal />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import TopBar from './TopBar.vue';
import SignModal from './SignModal.vue';
import FullScreenSearchArea from './FullScreenSearchArea.vue';
// import NavBar from './NavBar.vue';
const NavBar = defineAsyncComponent(() => import('./NavBar.vue'));

export default {
  name: 'Header',
  components: {
    TopBar, SignModal, FullScreenSearchArea, NavBar,
  },
};
</script>

<style scoped>
</style>
