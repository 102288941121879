<!-- eslint-disable max-len -->

<template>
  <div class="pt-5">
    <section class="hero">
      <div class="container">
        <!-- Breadcrumbs -->
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Frequently Asked Questions</li>
        </ol>
        <!-- Hero Content-->
        <div class="hero-content pb-5 text-center">
          <h1 class="mb-5">F.A.Q.s</h1>
          <div class="row">
            <div class="col-xl-8 offset-xl-2"><p class="lead mb-0"></p></div>
          </div>
        </div>
      </div>
    </section>
    <!-- FAQ-->
    <section class="pb-5">
      <div class="container">
        <div class="py-4">
          <h2 class="mb-5 text-muted">Wedding Launch & Electronic Invites</h2>
          <div class="row">
            <div class="col-md-6">
              <h5>Can I announce my engagement on social media?</h5>
              <p class="text-muted mb-4">Your wedding launch cards are shareable on social media with a link to view your account</p>
              <h5>Can I customize my announcement or e-invite cards?</h5>
              <p class="text-muted mb-4">Yes! This feature is in beta phase.</p>
            </div>
            <div class="col-md-6">
              <h5>Can I upload a predesigned card?</h5>
              <p class="text-muted mb-4">Yes! You can share your pre-designed wedding launch card with loved ones via social media as well.</p>
              <h5>Are all card templates free?</h5>
              <p class="text-muted mb-4">There are free templates and premum templates that you can choose from and customize.</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="py-4">
          <h2 class="mb-5 text-muted">Contributions & Payments</h2>
          <div class="row">
            <div class="col-md-6">
              <h5>Can I receive all weddings contributions on my account, even international ones?</h5>
              <p class="text-muted mb-4">Yes! Your loved ones from all over the world can support you using mobile money or credit cards. You can see this money in your e-Wallet.</p>
              <h5>Can I use my contributions to pay vendors?</h5>
              <p class="text-muted mb-4">Yes! You can use your contributons to pay vendors you select for your budget items using your e-Wallet.</p>
            </div>
            <div class="col-md-6">
              <h5>What if I pay the wrong vendor by mistake?</h5>
              <p class="text-muted mb-4">When you initiate a payment, your money is safely kept on a secure collection account. Vendors are paid a maximum of 80% a week prior to the wedding and 20% on the wedding day once you approve that they have delivered as expected.</p>
              <h5>Can I top-up my e-Wallet using mobile money or credit card?</h5>
              <p class="text-muted mb-4">Yes! You can top-up your e-Wallet to track and make your payments in one place.</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="py-4">
          <h2 class="mb-5 text-muted">Refunds</h2>
          <div class="row">
            <div class="col-md-6">
              <h5>What if a vendor does not show up?</h5>
              <p class="text-muted mb-4">Vendors who don't show up will be fined. Be sure to contact us and report such occurences immediately. Once verified, any payments made to the later shall be reimburse to your account.</p>
              <h5>What if the vendor delivers below expectations?</h5>
              <p class="text-muted mb-4">Do provide feedback and rate your vendors to help them improve their offerings.</p>
            </div>
            <div class="col-md-6">
              <h5>Can I convert my balance to cash or mobile money?</h5>
              <p class="text-muted mb-4">Yes! You can also use your balance to support other engaged couples if so wish.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
