<!-- eslint-disable max-len -->

<template>
  <div class="pt-5">
    <!-- Hero Section-->
    <section class="hero pt-3">
      <div class="container">
        <!-- Breadcrumbs -->
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">Logged Out</li>
        </ol>
        <!-- Hero Content-->
        <div class="hero-content pb-5 text-center">
          <h1 class="hero-heading mb-0">Log Out</h1>
        </div>
      </div>
    </section>
    <!-- customer login-->
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="block">
              <div class="block-header">
                <h6 class="mb-0 bg-light text-center text-success">Logged out successfully!</h6>
              </div>
              <div class="block-body">
                <p class="lead">Want to login again? <router-link to="/login">Login here</router-link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Logout',
};
</script>
