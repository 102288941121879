<template>
  <!-- eslint-disable max-len -->
  <div class="card mb-5 shadow-sm">
    <table class="table table-borderless table-hover table-responsive-md">
      <thead class="bg-light">
        <tr>
          <th class="text-uppercase text-sm">TxRef</th>
          <th class="text-uppercase text-sm">Item</th>
          <th class="text-uppercase text-sm">Vendor</th>
          <th class="text-uppercase text-sm">Amount</th>
          <th class="text-uppercase text-sm">Date</th>
          <!-- <th class="text-uppercase text-sm"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p,i) in PaymentsMade" :key="i">
          <td class="align-middle">#00{{i+1}}</td>
          <td class="align-middle">{{p.item}}</td>
          <td class="align-middle">{{p.vendor}}</td>
          <td class="align-middle">${{p.amount_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td>
          <td class="align-middle">{{p.date}}</td>
          <!-- <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm shadow-sm">View</a></td> -->
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="align-middle text-uppercase text-sm"><strong>Total</strong></td>
          <td></td>
          <td></td>
          <td class="align-middle"><strong>${{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</strong></td>
          <td></td>
          <!-- <td></td> -->
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PaymentsMadeComponent',
  props: {
  },
  computed: {
    ...mapState(['PaymentsMade']),
    total() {
      let t = 0;
      this.PaymentsMade.forEach((p) => {
        t += p.amount_paid;
      });
      return t;
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
