<!-- eslint-disable max-len -->

<template>
<div class="px- mt-6">
  <section class="hero">
    <div class="hero-content text-center mb-3 pt-5">
      <h3 class="hero-heading">Vendors</h3>
    </div>
  </section>
  <div class="container">
    <div class="row">
      <div class="products-grid col-xl-9 col-lg-9 order-lg-2">
        <header class="product-grid-header">
          <div class="mr-3 mb-3">
            Page <strong>{{page}}</strong> of <strong>{{vendorCount}}</strong> Vendors
          </div>
          <div class="mr-3 mb-3">
            <span class="mr-2">Show</span>
            <a href="#" class="product-grid-header-show" :class="limit === 5 ? 'active' : ''" @click="limit = 5">5 </a>
            <a href="#" class="product-grid-header-show" :class="limit === 10 ? 'active' : ''" @click="limit = 10">10 </a>
            <a href="#" class="product-grid-header-show" :class="limit === 20 ? 'active' : ''" @click="limit = 20">20 </a>
          </div>
          <div class="mb-3 d-flex align-items-center"><span class="d-inline-block mr-1">Sort by</span>
            <select class="custom-select w-auto border-0">
              <option value="orderby_0">Default</option>
              <option value="orderby_1">Popularity</option>
              <option value="orderby_2">Rating</option>
              <option value="orderby_3">Newest first</option>
            </select>
          </div>
        </header>
        <div class="row">
          <!-- vendors -->
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6" v-for="(v,i) in Vendors" :key="i">
            <div class="product vendor-card-img">
              <div class="product-image">
                <img :src="v.vendor_img_url" :alt="v.name" class="img-fluid" />
                <div class="product-hover-overlay">
                  <a href="#" class="product-hover-overlay-link"></a>
                  <div class="product-hover-overlay-buttons">
                    <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
                  </div>
                </div>
              </div>
              <div class="py-2">
                <p class="text-muted text-sm mb-1">{{v.category}}: {{v.services}}</p>
                <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">{{v.name}}</a></h3>
                <small class="mr-1 bg-warning shadow-sm px-1">Starting</small> <span class="text-muted">${{v.price}}</span>
              </div>
            </div>
          </div>
          <!-- /vendors -->
        </div>
        <nav aria-label="page navigation" class="d-flex justify-content-center mb-5 mt-3" v-if="vendorCount > 12">
          <ul class="pagination">
            <li class="page-item"><a href="#" aria-label="Previous" class="page-link" @click="page-=1"><span aria-hidden="true">Prev</span><span class="sr-only">Previous</span></a></li>
            <li class="page-item" :class="page === 1 ? 'active' : ''"><a href="#" class="page-link" @click="page=1">1 </a></li>
            <li class="page-item" :class="page === 2 ? 'active' : ''"><a href="#" class="page-link" @click="page=2">2 </a></li>
            <li class="page-item" :class="page === 3 ? 'active' : ''"><a href="#" class="page-link" @click="page=3">3 </a></li>
            <li class="page-item"><a href="#" aria-label="Next" class="page-link" @click="page+=1"><span aria-hidden="true">Next</span><span class="sr-only">Next </span></a></li>
          </ul>
        </nav>
      </div>
      <div class="sidebar col-xl-3 col-lg-3 order-lg-1 mt-4 mt-sidebar">
        <div class="sidebar-block px-3 px-lg-0 mr-lg-4"><a class="d-lg-none block-toggler collapsed" data-toggle="collapse" href="#brandFilterMenu" aria-expanded="false" aria-controls="brandFilterMenu">Filter by category</a>
          <!-- Category filter menu - this menu has .show class, so is expanded by default-->
          <div class="expand-lg collapse" id="brandFilterMenu" style="">
            <h6 class="sidebar-heading d-none d-lg-block">Categories </h6>
            <form class="mt-4 mt-lg-0" action="#">
              <div class="form-group mb-1">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" id="all-vendors" type="checkbox" name="all-vendors" v-model="all" @change="checked">
                  <label class="custom-control-label" for="all-vendors">All  <small></small></label>
                </div>
              </div>
              <div class="form-group mb-1" v-for="(c,i) in Categories" :key="i">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" v-model="categoryObject[i]" :ref="`vendor_category_${i}`" :id="`vendor-${c.category}`" type="checkbox" :name="c.category" @change="addCategory">
                  <label class="custom-control-label" :for="`vendor-${c.category}`">{{c.category}}  <small></small></label>
                </div>
              </div>
              <div class="form-group mb-1">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" id="other-vendors" type="checkbox" name="all-vendors" v-model="other" @change="addCategory">
                  <label class="custom-control-label" for="other-vendors">Other  <small></small></label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="sidebar-block px-3 px-lg-0 mr-lg-4"><a class="d-lg-none block-toggler" data-toggle="collapse" href="#priceFilterMenu" aria-expanded="false" aria-controls="priceFilterMenu">Filter by price</a>
          <div class="expand-lg collapse" id="priceFilterMenu">
            <h6 class="sidebar-heading d-none d-lg-block">Price Range</h6>
            <div class="mt-4 mt-lg-0 noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr" id="slider-snap">
              <div class="noUi-base">
                <div class="noUi-connects">
                  <div class="noUi-connect" style="transform: translate(16%, 0px) scale(0.28, 1);"></div>
                </div>
                <div class="noUi-origin" style="transform: translate(-840%, 0px); z-index: 5;">
                  <div class="noUi-handle noUi-handle-lower" data-handle="0" tabindex="0" role="slider" aria-orientation="horizontal" aria-valuemin="0.0" aria-valuemax="110.0" aria-valuenow="40.0" aria-valuetext="40.00">
                    <div class="noUi-touch-area"></div>
                  </div>
                </div>
                <div class="noUi-origin" style="transform: translate(-560%, 0px); z-index: 6;">
                  <div class="noUi-handle noUi-handle-upper" data-handle="1" tabindex="0" role="slider" aria-orientation="horizontal" aria-valuemin="40.0" aria-valuemax="250.0" aria-valuenow="110.0" aria-valuetext="110.00">
                    <div class="noUi-touch-area"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nouislider-values">
              <div class="min">From $<span id="slider-snap-value-lower">40.00</span></div>
              <div class="max">To $<span id="slider-snap-value-upper">110.00</span></div>
              <input class="slider-snap-input" type="hidden" name="pricefrom" id="slider-snap-input-lower" value="40">
              <input class="slider-snap-input" type="hidden" name="priceto" id="slider-snap-input-upper" value="110">
            </div>
          </div>
        </div> -->
        <div class="sidebar-block px-3 px-lg-0 mr-lg-4"> <a class="d-lg-none block-toggler" data-toggle="collapse" href="#sizeFilterMenu" aria-expanded="false" aria-controls="sizeFilterMenu">Filter by price</a>
          <div class="expand-lg collapse" id="sizeFilterMenu">
            <h6 class="sidebar-heading d-none d-lg-block">Price Range </h6>
            <form class="mt-4 mt-lg-0" @submit.prevent="setPriceRange">
              <div class="form-group mb-4">
                <label for="min-price" class="form-label">Min<span>*</span></label>
                <input v-model="min_price" type="number" name="min-price" class="form-control form-control-sm" placeholder="">
                <label for="max-price" class="form-label">Max<span>*</span></label>
                <input v-model="max_price" type="number" name="max-price" class="form-control form-control-sm" placeholder="">
                <label for="currency" class="form-label">Currency<span>*</span></label>
                <select class="form-control form-control-sm" v-model="currency">
                  <option disabled value="">Select currency</option>
                  <option v-for="(c,i) in currencies" :key="i"  :value="`${c.country} - ${c.currency}`">{{c.country}} - {{c.currency}}</option>
                </select>
              </div>
              <div class="form-group text-center">
                <button type="submit" class="btn btn-outline-dark btn-sm">Filter</button>
              </div>
              <!-- <div class="form-group mb-1">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" id="range0" type="radio" name="range" checked="" @change="setPriceRange">
                  <label class="custom-control-label" for="range0">Any</label>
                </div>
              </div>
              <div class="form-group mb-1">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" id="range1" type="radio" name="range" @change="setPriceRange">
                  <label class="custom-control-label" for="range1">Less than $1,000</label>
                </div>
              </div>
              <div class="form-group mb-1">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" id="range2" type="radio" name="range" @change="setPriceRange">
                  <label class="custom-control-label" for="range2">$1,000 - $10,000</label>
                </div>
              </div>
              <div class="form-group mb-1">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" id="range3" type="radio" name="range" @change="setPriceRange">
                  <label class="custom-control-label" for="range3">$10,000 - $100,000</label>
                </div>
              </div>
              <div class="form-group mb-1">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" id="range4" type="radio" name="range" @change="setPriceRange">
                  <label class="custom-control-label" for="range4">More than $100,000</label>
                </div>
              </div> -->
            </form>
          </div>
        </div>
        <!-- <div class="sidebar-block px-3 px-lg-0 mr-lg-4"><a class="d-lg-none block-toggler" data-toggle="collapse" href="#colourFilterMenu" aria-expanded="false" aria-controls="colourFilterMenu">Filter by colour</a>
          <div class="expand-lg collapse" id="colourFilterMenu">
            <h6 class="sidebar-heading d-none d-lg-block">Colour </h6>
            <div class="mt-4 mt-lg-0">
              <ul class="list-inline mb-0 colours-wrapper">
                <li class="list-inline-item">
                  <label class="btn-colour" for="colour_sidebar_Blue" style="background-color: #668cb9" data-allow-multiple=""> </label>
                  <input class="input-invisible" type="checkbox" name="colour" value="value_sidebar_Blue" id="colour_sidebar_Blue">
                </li>
                <li class="list-inline-item">
                  <label class="btn-colour" for="colour_sidebar_White" style="background-color: #fff" data-allow-multiple=""> </label>
                  <input class="input-invisible" type="checkbox" name="colour" value="value_sidebar_White" id="colour_sidebar_White">
                </li>
                <li class="list-inline-item">
                  <label class="btn-colour" for="colour_sidebar_Violet" style="background-color: #8b6ea4" data-allow-multiple=""> </label>
                  <input class="input-invisible" type="checkbox" name="colour" value="value_sidebar_Violet" id="colour_sidebar_Violet">
                </li>
                <li class="list-inline-item">
                  <label class="btn-colour" for="colour_sidebar_Red" style="background-color: #dd6265" data-allow-multiple=""> </label>
                  <input class="input-invisible" type="checkbox" name="colour" value="value_sidebar_Red" id="colour_sidebar_Red">
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <!-- <div class="sidebar-block px-3 px-lg-0 mr-lg-4"><a class="d-lg-none block-toggler" data-toggle="collapse" href="#categoriesMenu" aria-expanded="false" aria-controls="categoriesMenu">Product Categories</a>
          <div class="expand-lg collapse" id="categoriesMenu">
            <div class="nav nav-pills flex-column mt-4 mt-lg-0"> <a class="nav-link d-flex justify-content-between mb-2 " href="#"><span>Jackets</span><span class="sidebar-badge"> 120</span></a>
              <div class="nav nav-pills flex-column ml-3"><a class="nav-link mb-2" href="#">Lorem ipsum</a><a class="nav-link mb-2" href="#">Dolor</a><a class="nav-link mb-2" href="#">Sit amet</a><a class="nav-link mb-2" href="#">Donec vitae</a>
              </div><a class="nav-link d-flex justify-content-between mb-2 active" href="#"><span>Jeans &amp; chinos</span><span class="sidebar-badge"> 55</span></a>
              <div class="nav nav-pills flex-column ml-3"><a class="nav-link mb-2" href="#">Lorem ipsum</a><a class="nav-link mb-2" href="#">Dolor</a><a class="nav-link mb-2" href="#">Sit amet</a><a class="nav-link mb-2" href="#">Donec vitae</a>
              </div><a class="nav-link d-flex justify-content-between mb-2 " href="#"><span>Accessories</span><span class="sidebar-badge"> 80</span></a>
              <div class="nav nav-pills flex-column ml-3"><a class="nav-link mb-2" href="#">Sit amet</a><a class="nav-link mb-2" href="#">Donec vitae</a><a class="nav-link mb-2" href="#">Lorem ipsum</a><a class="nav-link mb-2" href="#">Dolor</a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'vendors',
  data: () => ({
    all: true,
    other: false,
    categories: [],
    categoryObject: {},
    min_price: 0,
    max_price: null,
    currency: '',
    limit: 5,
    page: 1,
  }),
  methods: {
    checked(e) {
      console.log(e.target.checked);
      if (this.categories.length === 0) this.all = true;
      if (this.all === true) this.other = false;
      // console.log(this.all);
      // reset checked for categories
      this.categories = [];
      // reset v-model for categories
      this.categoryObject = {};
      this.$router.push({ path: 'vendors', query: { filter: this.categories } });
      // console.log(this.$route.query.filter);
      console.log(this.$route.fullPath);
    },
    addCategory(e) {
      if (e.target.checked) this.categories.push(e.target.name);
      if (!e.target.checked) {
        this.categories = this.categories.filter((c) => c !== e.target.name);
      }
      if (this.categories.length > 0) this.all = false;
      if (this.categories.length === 0) this.all = true;
      this.$router.push({ path: 'vendors', query: { fltr: this.categories } });
      console.log(this.$route.fullPath);
      // console.log(this.$route.query.filter);
    },
    setPriceRange(e) {
      this.categories.push(e.target);
      console.log(this.Currencies);
    },
  },
  computed: {
    ...mapState(['Vendors', 'Categories', 'Countries']),
    ...mapGetters(['currencies']),
    vendorCount() {
      return this.Vendors.length;
    },
    Currencies() {
      const cs = [];
      this.Countries.forEach((c) => {
        if (c.currencies[0]) cs.push({ country: c.name, currency: c.currencies[0] });
      });
      return cs.sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = a.country.toUpperCase();
        const nameB = b.country.toUpperCase();
        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });
    },
  },
};
</script>
<style scoped>
@media (min-width: 992px) {
  .mt-sidebar {
    margin-top: .75rem !important;
  }
}
</style>
