<!-- eslint-disable max-len -->

<template>
  <div class="docs-content px-lg-3">
    <div class="hero-content text-center mb-4">
      <h3 class="hero-heading">Our Wallet</h3>
    </div>
    <div class="my-4">
      <h5 class="text-uppercase mb-4" style="font-size: 1rem;">E-Wallet</h5>
      <table class="table table-borderless table-hover table-responsive-md shadow-sm">
        <thead class="bg-light">
          <tr>
            <!-- <th class="text-sm">Top-ups</th> -->
            <!-- <th class="text-sm">Contributions</th> -->
            <!-- <th class="text-sm">Expenditure</th> -->
            <!-- <th class="text-sm">Withdrawals</th> -->
            <th class="text-sm text-center">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!-- <td class="align-middle">${{wallet.topups.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td> -->
            <!-- <td class="align-middle">${{wallet.contributions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td> -->
            <!-- <td class="align-middle">${{wallet.expenditure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td> -->
            <!-- <td class="align-middle">${{wallet.withdrawals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td> -->
            <td class="align-middle text-center"><strong>${{balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</strong></td>
          </tr>
        </tbody>
      </table>
      <div class="text-center my-4 d-flex justify-content-center">
        <button type="submit" class="btn btn-outline-dark d-flex justify-content-center align-items-center">
          <svg width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-up"><path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/></svg>
          <span class="ml-2">Top up</span>
        </button>
      </div>
    </div>
    <!-- <div>
      <h3 class="text-uppercase mb-4" style="font-size: 1rem;">Payment Details</h3>
      <p>Payments methods available are credit card, bank account and mobile money. Add or update your details to start transacting.</p>
      <div id="accordion" role="tablist" v-if="true">
        <div class="block mb-3">
          <div class="block-header" id="headingOne" role="tab"><strong><a class="accordion-link" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Credit Card</a></strong></div>
          <div class="collapse show" id="collapseOne" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style="">
            <div class="block-body">
              <form @submit.prevent="addCardDetails">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="card-name">Name on Card</label>
                    <input v-model="credit_card.name" class="form-control" type="text" name="card-name" placeholder="Name on card" id="card-name" required>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="card-number">Card Number</label>
                    <input v-model="credit_card.number" v-maska="'#### #### #### ####'" class="form-control" type="text" name="card-number" placeholder="Card number" id="card-number" required>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label" for="expiry-date">Expiry Date</label>
                    <input v-model="credit_card.expiry_date" v-maska="{ mask: '##/##', tokens: { 'M': { pattern: /0?[1-9]|1[012]/ }} }" class="form-control" type="text" name="expiry-date" placeholder="MM/YY" id="expiry-date" required>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label" for="cvv">CVC/CVV</label>
                    <input v-model="credit_card.cvv_cvc" class="form-control" type="text" name="cvv" placeholder="123" id="cvv" required>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label" for="zip">ZIP</label>
                    <input v-model="credit_card.zip" class="form-control" type="text" name="zip" placeholder="123" id="zip" required>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button type="submit" class="btn btn-outline-dark"><i class="far fa-save mr-2"></i>Save Card Details</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="block mb-3">
          <div class="block-header" id="headingTwo" role="tab"><strong><a class="accordion-link collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Bank Details</a></strong></div>
          <div class="collapse" id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion" style="">
            <div class="block-body">
              <form @submit.prevent="addBankDetails">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label" for="bank-code">Bank Code</label>
                    <input v-model="bank.code" class="form-control" type="text" name="bank-code" placeholder="Enter bank code" id="bank-code" required>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="account-number">Account Number</label>
                    <input v-model="bank.account_number" class="form-control" type="text" name="account-number" placeholder="Account number" id="account-number" required>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="bank-name">Bank Name</label>
                    <input v-model="bank.name" class="form-control" type="text" name="bank-name" placeholder="Enter bank name" id="bank-name" required>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="account-name">Account Name</label>
                    <input v-model="bank.account_name" class="form-control" type="text" name="account-name" placeholder="Name of bank account" id="account-name" required>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button type="submit" class="btn btn-outline-dark"><i class="far fa-save mr-2"></i>Save Bank Details</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="block mb-3">
          <div class="block-header" id="headingThree" role="tab"><strong><a class="accordion-link collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Mobile Money</a></strong></div>
          <div class="collapse" id="collapseThree" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion" style="">
            <div class="block-body">
              <form @submit.prevent="addMobileMoneyDetails">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label class="form-label" for="currency">Currency</label>
                    <select v-model="mobile_money.currency" id="currency" name="currency" class="form-control" required>
                      <option selected disabled class="bg-light text-disabled">select</option>
                      <option :value="c.currency_iso" v-for="(c,i) in currency_list" :key="i">{{c.country}} - {{c.currency_iso}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label" for="mobile-money-no">Mobile Money No.</label>
                    <input v-model="mobile_money.number" class="form-control" type="text" name="mobile-money-no" placeholder="Mobile money no." id="mobile-money-no" required>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <button type="submit" class="btn btn-outline-dark"><i class="far fa-save mr-2"></i>Save Mobile Money Details</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'e-wallet',
  data: () => ({
    credit_card: {
      name: '',
      number: '',
      expiry_date: '',
      cvv_cvc: '',
      zip: '',
    },
    bank: {
      code: '',
      account_number: '',
      account_name: '',
      name: '',
    },
    mobile_money: {
      currency: '',
      number: '',
      country: '',
      currency_iso: '',
      zip: '',
    },
    currency_list: [
      { currency_iso: 'XOF', country: 'Benin', currency: 'CFA franc' },
      { currency_iso: 'BWP', country: 'Botswana', currency: 'Pula' },
      { currency_iso: 'XOF', country: 'Burkina', currency: 'Faso CFA franc' },
      { currency_iso: 'BIF', country: 'Burundi', currency: 'Burundi franc' },
      { currency_iso: 'XAF', country: 'Cameroon', currency: 'CFA franc' },
      { currency_iso: 'XAF', country: 'Chad', currency: 'CFA franc' },
      { currency_iso: 'CDF', country: 'D.R.Congo', currency: 'Congolese franc' },
      { currency_iso: 'EGP', country: 'Egypt', currency: 'Egyptian pound' },
      { currency_iso: 'ETB', country: 'Ethiopia', currency: 'Ethiopian birr' },
      { currency_iso: 'XAF', country: 'Gabon', currency: 'CFA franc' },
      { currency_iso: 'GHS', country: 'Ghana', currency: 'Ghanaian cedi' },
      { currency_iso: 'GNF', country: 'Guinea', currency: 'Guinean franc' },
      { currency_iso: 'XOF', country: 'Guinea-Bissau', currency: 'CFA franc' },
      { currency_iso: 'XOF', country: 'Ivory Coast', currency: 'CFA franc' },
      { currency_iso: 'KES', country: 'Kenya', currency: 'Kenyan shilling' },
      { currency_iso: 'LSL', country: 'Lesotho', currency: 'Loti' },
      { currency_iso: 'LRD', country: 'Liberia', currency: 'Liberian dollar' },
      { currency_iso: 'MGA', country: 'Madagascar', currency: 'Malagasy ariary' },
      { currency_iso: 'MWK', country: 'Malawi', currency: 'Malawian kwacha' },
      { currency_iso: 'XOF', country: 'Mali', currency: 'CFA franc' },
      { currency_iso: 'MRO', country: 'Mauritania', currency: 'Ouguiya' },
      { currency_iso: 'MUR', country: 'Mauritius', currency: 'Mauritian rupee' },
      { currency_iso: 'MAD', country: 'Morocco', currency: 'Moroccan dirham' },
      { currency_iso: 'MZN', country: 'Mozambique', currency: 'Mozambican metical' },
      { currency_iso: 'NAD', country: 'Namibia', currency: 'Namibian dollar' },
      { currency_iso: 'XOF', country: 'Niger', currency: 'CFA franc' },
      { currency_iso: 'NGN', country: 'Nigeria', currency: 'Naira' },
      { currency_iso: 'RWF', country: 'Rwanda', currency: 'Rwandan franc' },
      { currency_iso: 'XOF', country: 'Senegal', currency: 'CFA franc' },
      { currency_iso: 'SLL', country: 'Sierra', currency: 'Leone Sierra Leonean leone' },
      { currency_iso: 'SOS', country: 'Somalia', currency: 'shilling' },
      { currency_iso: 'ZAR', country: 'South Africa', currency: 'South African rand' },
      { currency_iso: 'SDG', country: 'Sudan', currency: 'Sudanese pound' },
      { currency_iso: 'SZL', country: 'Swaziland', currency: 'Lilangeni' },
      { currency_iso: 'TZS', country: 'Tanzania', currency: 'Tanzanian shilling' },
      { currency_iso: 'XOF', country: 'Togo', currency: 'CFA franc' },
      { currency_iso: 'TND', country: 'Tunisia', currency: 'Tunisian dinar' },
      { currency_iso: 'UGX', country: 'Uganda', currency: 'Ugandan shilling' },
      { currency_iso: 'ZMW', country: 'Zambia', currency: 'Zambian kwacha' },
      { currency_iso: 'ZWD', country: 'Zimbabwe', currency: 'Zimbabwean dollar' },
    ],
    message: {
      error: '',
      success: '',
    },
    wallet: {
      topups: 16000,
      contributions: 400,
      withdrawals: 0,
      expenditure: 15951,
      balance: null,
    },
  }),
  methods: {
    addCardDetails(e) {
      e.preventDefault();
      // console.log(this.credit_card);
      const id = localStorage.getItem('_ud');
      const token = localStorage.getItem('_ikn');
      axios.put(`${this.surl}/api/users/couples/${id}/update/wallet`, {
        credit_card: this.credit_card,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          const account = 'Couple';
          this.$store.dispatch('setCurrentUser', { account });
          this.message.error = '';
          this.message.success = response.data.message;
        })
        .catch((err) => {
          console.log(err.response);
          this.message.success = '';
          this.message.error = err.response.data.message;
        });
    },
    addBankDetails(e) {
      e.preventDefault();
      // console.log(this.bank);
      const id = localStorage.getItem('_ud');
      const token = localStorage.getItem('_ikn');
      axios.put(`${this.surl}/api/users/couples/${id}/update/wallet`, {
        bank: this.bank,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const account = 'Couple';
          this.$store.dispatch('setCurrentUser', { account });
          this.message.error = '';
          this.message.success = response.data.message;
        })
        .catch((err) => {
          this.message.success = '';
          this.message.error = err.response.data.message;
        });
    },
    addMobileMoneyDetails(e) {
      e.preventDefault();
      // console.log(this.mobile_money);
      const id = localStorage.getItem('_ud');
      const token = localStorage.getItem('_ikn');
      axios.put(`${this.surl}/api/users/couples/${id}/update/wallet`, {
        mobile_money: this.mobile_money,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const account = 'Couple';
          this.$store.dispatch('setCurrentUser', { account });
          this.message.error = '';
          this.message.success = response.data.message;
        })
        .catch((err) => {
          this.message.success = '';
          this.message.error = err.response.data.message;
        });
    },
    getPaymentDetails() {
      this.credit_card = this.payment_details.credit_card;
      this.bank = this.payment_details.bank;
      this.mobile_money = this.payment_details.mobile_money;
    },
  },
  computed: {
    ...mapGetters(['payment_details', 'surl']),
    balance() {
      const w = this.wallet;
      const b = w.topups + w.contributions - w.withdrawals - w.expenditure;
      return b;
    },
  },
  created() {
    this.getPaymentDetails();
  },
};
</script>
<style scoped>
</style>
