<!-- eslint-disable max-len -->

<template>
<div class="pt-5">
  <!-- Hero Section-->
  <section class="hero pt-3">
    <div class="container">
      <!-- Breadcrumbs -->
      <ol class="breadcrumb justify-content-center">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active">Register</li>
      </ol>
      <!-- Hero Content-->
      <div class="hero-content pb-5 text-center">
        <h1 class="hero-heading mb-0">Register</h1>
      </div>
    </div>
  </section>
  <!-- customer signup-->
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="block">
            <div class="block-header">
              <h6 class="text-uppercase mb-0">Create account</h6>
            </div>
            <div class="block-body">
              <p class="lead">Already registered? <router-link to="/login">Login here</router-link></p>
              <p class="text-muted">If you have any questions, please feel free to <a href="/contact">contact us</a>, our customer service center is working for you 24/7.</p>
              <hr>
              <transition name="fade">
                <p v-if="message.error" class="px-3 py-2 alert alert-danger mb-3">{{message.error}}</p>
              </transition>
              <transition name="fade">
                <p v-if="message.success" class="px-3 py-2 alert alert-success mb-3">{{message.success}}</p>
              </transition>
              <form @submit.prevent="registerUser">
                <!-- <p v-if="error" class="text-white" :class="error ? 'bg-danger':'bg-success'">{{error}}</p> -->
                <div class="form-group">
                  <label for="name" class="form-label">Username</label>
                  <input type="text" class="form-control" :class="validation.username?'border-primary':''" v-model="username">
                  <small class="text-danger"><em>{{validation.username}}</em></small>
                </div>
                <div class="form-group">
                  <label for="name" class="form-label">Account Type</label>
                  <select class="form-control" :class="validation.account?'border-primary':''" v-model="account">
                    <option disabled value="">Select account type</option>
                    <option value="Couple">Couple <em><small>(heterosexual only)</small></em></option>
                    <option value="Guest">Guest</option>
                    <option value="Vendor">Vendor</option>
                  </select>
                  <small class="text-danger"><em>{{validation.account}}</em></small>
                </div>
                <div class="form-group">
                  <label for="email" class="form-label">Email</label>
                  <input type="email" class="form-control" :class="validation.email?'border-primary':''" v-model="email_r">
                  <small class="text-danger"><em>{{validation.email}}</em></small>
                </div>
                <div class="form-group">
                  <label for="password" class="form-label">Password</label>
                  <input type="password" class="form-control" :class="validation.password?'border-primary':''" v-model="password_r">
                  <small class="text-danger"><em>{{validation.password}}</em></small>
                </div>
                <div class="form-group text-center">
                  <!-- <button class="btn btn-outline-dark" type="submit"><i class="far fa-user mr-2"></i>Register </button> -->
                  <button type="submit" class="btn btn-outline-dark" :disabled="loader">
                      <div class="d-flex justify-content-center align-items-center">
                        <i v-if="!loader" class="far fa-user mr-2"></i>
                        <span>Register</span>
                        <span v-if="loader" class="ml-2 spinner-grow spinner-grow-sm" role="status"></span>
                        <span v-if="loader" class="sr-only">Loading...</span>
                      </div>
                    </button>
                </div>
              </form>
              <!-- <p>{{ username }} {{ account }} {{ email_r }} {{ password_r }}</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import axios from 'axios';
// import firebase from 'firebase/app';
// import 'firebase/auth';
// eslint-disable max-len

export default {
  name: 'register',
  data: () => ({
    username: '',
    account: '',
    email_r: '',
    password_r: '',
    loader: false,
    message: {
      error: '',
      success: '',
    },
    validation: {
      username: '',
      account: '',
      email: '',
      password: '',
    },
  }),
  methods: {
    clearRegisterFormData() {
      this.username = '';
      this.account = '';
      this.email_r = '';
      this.password_r = '';
      this.loader = false;
    },
    async registerUser(e) {
      e.preventDefault();
      this.loader = true;
      // firebase.auth()
      //   .createUserWithEmailAndPassword(this.email_r, this.password_r)
      //   .then((user) => {
      //     console.log(`Account created for ${user.username}`);
      //     this.$router.push('/');
      //   }).catch((err) => {
      //     console.log(err.message);
      //   });
      // if (!this.username) {
      //   this.validation.username = 'enter username';
      // } else {
      //   this.validation.username = '';
      // }
      this.validation.username = this.username === '' ? 'select desired account' : '';
      this.validation.account = this.account === '' ? 'select desired account' : '';
      this.validation.email = this.email_r === '' ? 'enter valid email address' : '';
      this.validation.password = this.password_r === '' ? 'enter account password' : '';
      this.validation.password = this.password_r.length < 6 ? 'Password should be atleast 6 characters in length!' : '';
      if (this.username && this.account && this.email_r && this.password_r) {
        axios.post('http://localhost:3000/api/auth/users/register', {
          username: this.username,
          account: this.account,
          email: this.email_r,
          password: this.password_r,
        })
          .then((response) => {
            this.message.error = '';
            // this.loader = false;
            console.log(response.data);
            this.message.success = response.data.message;
            this.clearRegisterFormData();
            this.$router.push('/login');
          })
          .catch((err) => {
            this.message.success = '';
            this.loader = false;
            console.log(err.response.data);
            this.message.error = err.response.data.message;
          });
      }
    },
  },
};
</script>
