<!-- eslint-disable max-len -->

<template>
  <section class="home-full-slider-wrapper mb-10px">
    <!-- Hero Slider-->
    <div id="owl-slideshow" class="owl-carousel owl-theme owl-dots-modern home-full-slider ">
      <div class="item home-full-item  dark-overlay" style="background: #f8d5cf;">
        <img :src="require(`../assets/bb4.jpg`)" alt="couples" class="bg-image">
        <div class="container-fluid h-100 py-5">
          <div class="row align-items-center h-100">
            <div class="col-lg-8 col-xl-6 mx-auto text-white text-center overlay-content">
              <h5 class="text-uppercase text-white font-weight-light mt-4 mb-4 letter-spacing-5">
                Welcome!</h5>
              <h1 class="mb-5 display-2 font-weight-bold text-serif">Bride & Groom</h1>
              <p class="lead mb-4"> Realize your dream wedding within your budget.
                Announce your wedding, manage your contributions, select & pay vendors,
                send out electronic invites, create gift registries and book honeymoon
                experiences all in one place.</p>
              <p>
                <!-- <a href="/register" id="bb-home-btn" class="btn btn-light" data-toggle="modal" data-target="#myModal">Get Started</a></p> -->
                <a href="/register" id="bb-home-btn" class="btn btn-light" v-if="loggedIn">Get Started</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="item home-full-item bg-dark dark-overlay">
        <img :src="require(`../assets/guests11.jpg`)" alt="guests" class="bg-image">
        <div class="container-fluid h-100">
          <div class="row align-items-center h-100">
            <div class="col-lg-8 col-xl-6 mx-auto text-white text-center overlay-content">
              <h5 class="text-uppercase text-white font-weight-light mt-4 mb-4 letter-spacing-5">
                Welcome! </h5>
              <h1 class="mb-4 display-2 text-uppercase font-weight-bold">Guests</h1>
              <p class="lead mb-5">Discover and contribute to upcoming weddings of friends, family or
                colleagues, all with one platform. Attend online if you can't make it. Buy something
                special for the couple from their gift registry while you are at it.</p>
              <!-- <a href="/register" class="btn btn-light" data-toggle="modal" data-target="#myModal">Start here</a> -->
              <a href="/register" class="btn btn-light" v-if="loggedIn">Start here</a>
            </div>
          </div>
        </div>
      </div>
      <div class="item home-full-item dark-overlay">
        <img :src="require(`../assets/venues4.jpg`)" alt="venues" class="bg-image">
        <div class="container-fluid h-100">
          <div class="row align-items-center h-100">
            <div class="col-lg-8 col-xl-6 mx-auto text-white text-center overlay-content">
              <h5 class="text-uppercase font-weight-light mb-4 letter-spacing-5">
                Join our service providers! </h5>
              <h1 class="mb-5 display-1 font-weight-bold text-serif">Vendors</h1>
              <p class="lead mb-5">Let couples book you to help bring their dream
                weddings to life.</p>
              <!-- <p> <a href="/register" class="btn btn-light" data-toggle="modal" data-target="#myModal">Register</a></p> -->
              <p><a href="/register" class="btn btn-light" v-if="loggedIn">Register</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
  name: 'home',
  data: () => ({
  }),
  computed: {
    ...mapGetters(['token']),
    loggedIn() {
      if (this.token) return false;
      return true;
    },
  },
  mounted() {
  },
};
</script>
