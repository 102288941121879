// jshint esversion:6
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue';
// import firebase from 'firebase';
import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import FAQs from '../views/FAQs.vue';
import Logout from '../views/Logout.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';

import BrideNGroom from '../views/Couples/BrideNGroom.vue';
// import Couple from '../views/Couples/Couple.vue';
import CoupleLaunch from '../components/Couples/LaunchWedding.vue';
import CoupleBookVendors from '../components/Couples/BookVendors.vue';
import CoupleContributionsReceived from '../components/Couples/ContributionsReceived.vue';
// import GuestList from '../components/Couples/GuestList.vue';
// import WishList from '../components/Couples/WishList.vue';
import CoupleProfile from '../components/Couples/CoupleProfile.vue';
import CoupleWallet from '../components/Couples/E-Wallet.vue';
// import UpcomingWeddings from '../components/Couples/UpcomingWeddings.vue';
import CouplePaymentsMade from '../components/Couples/PaymentsMade.vue';

// import Guest from '../views/Guests/Guest.vue';
import GuestProfile from '../components/Guests/GuestProfile.vue';
import GuestWallet from '../components/Guests/E-Wallet.vue';
import GuestUpcomingWeddings from '../components/Guests/UpcomingWeddings.vue';
import GuestContributionsGiven from '../components/Guests/ContributionsGiven.vue';
import GuestInvites from '../components/Guests/E-Invites.vue';
import GuestGiftsGiven from '../components/Guests/GiftsGiven.vue';

// import Vendor from '../views/Vendors/Vendor.vue';
import VendorWallet from '../components/Vendors/E-Wallet.vue';
import VendorOfferings from '../components/Vendors/Offerings.vue';
import VendorPaymentsReceived from '../components/Vendors/PaymentsReceived.vue';
import VendorUpcomingWeddings from '../components/Vendors/UpcomingWeddings.vue';
import VendorBookings from '../components/Vendors/Bookings.vue';
import VendorProfile from '../components/Vendors/VendorProfile.vue';

import Settings from '../views/Users/Settings.vue';

import CouplePage from '../views/CouplePage.vue';
import GuestPage from '../views/GuestPage.vue';
import VendorPage from '../views/VendorPage.vue';

import Vendors from '../views/Vendors.vue';
import VendorCategories from '../views/VendorCategories.vue';
import Honeymoon from '../views/Honeymoon.vue';
import HoneymoonCategories from '../views/HoneymoonCategories.vue';

import Oops from '../views/404.vue';

// import store from '../store/index';

// const Couple = defineAsyncComponent(() => import('../views/Couples/Couple.vue'));
// const Couple = () => import('../views/Couples/Couple.vue');
const Guest = defineAsyncComponent(() => import('../views/Guests/Guest.vue'));
// const Guest = () => import('../views/Guests/Guest.vue');
const Vendor = defineAsyncComponent(() => import('../views/Vendors/Vendor.vue'));
// const Vendor = () => import('../views/Vendors/Vendor.vue');
// const Home = defineAsyncComponent(() => import('../views/Home.vue'));
// const About = defineAsyncComponent(() => import('../views/About.vue'));

NProgress.configure({ easing: 'ease', speed: 500 });

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/sample/bride-n-groom',
    name: 'bride-n-groom',
    component: BrideNGroom,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/couples/:username',
    name: 'couple-page',
    component: CouplePage,
  },
  {
    path: '/guests/:username',
    name: 'guest-page',
    component: GuestPage,
  },
  {
    path: '/vendors/:username',
    name: 'vendor-page',
    component: VendorPage,
  },
  {
    path: '/couple',
    name: 'couple',
    component: () => import('../views/Couples/Couple.vue'),
    // component: () => import(/* webpackChunkName: "couple" */ '../views/Couples/Couple.vue'),
    meta: {
      requiresAuth: true,
      requiresCouple: true,
    },
    children: [
      {
        path: 'profile',
        name: 'couple-profile',
        components: {
          // default: CoupleProfile,
          couple: CoupleProfile,
          // couple: () => import(/* webpackChunkName: "couple-profile" */ '../components/Couples/CoupleProfile.vue'),
        },
      },
      {
        path: 'wallet',
        name: 'couple-wallet',
        components: {
          // default: CoupleWallet,
          couple: CoupleWallet,
          // couple: () => import(/* webpackChunkName: "couple-wallet" */ '../components/Couples/E-Wallet.vue'),
        },
      },
      {
        path: 'launch',
        name: 'couple-launch',
        components: {
          // default: CoupleLaunch,
          couple: CoupleLaunch,
          // couple: () => import(/* webpackChunkName: "couple-launch" */ '../components/Couples/LaunchWedding.vue'),
        },
      },
      {
        path: 'budget',
        name: 'couple-budget',
        components: {
          // default: CoupleBookVendors,
          couple: CoupleBookVendors,
          // couple: () => import(/* webpackChunkName: "couple-budget" */ '../components/Couples/BookVendors.vue'),
        },
      },
      {
        path: 'contributions',
        name: 'couple-contributions',
        components: {
          // default: CoupleContributionsReceived,
          couple: CoupleContributionsReceived,
          // couple: () => import(/* webpackChunkName: "couple-contributions" */ '../components/Couples/ContributionsReceived.vue'),
        },
      },
      {
        path: 'transactions',
        name: 'couple-transactions',
        components: {
          // default: CouplePaymentsMade,
          couple: CouplePaymentsMade,
          // couple: () => import(/* webpackChunkName: "couple-transactions" */ '../components/Couples/PaymentsMade.vue'),
        },
      },
    ],
  },
  {
    path: '/guest',
    name: 'guest',
    component: Guest,
    meta: {
      requiresAuth: true,
      requiresGuest: true,
    },
    children: [
      {
        path: 'profile',
        name: 'guest-profile',
        components: {
          guest: GuestProfile,
        },
      },
      {
        path: 'wallet',
        name: 'guest-wallet',
        components: {
          guest: GuestWallet,
        },
      },
      {
        path: 'upcoming',
        name: 'guest-upcoming',
        components: {
          guest: GuestUpcomingWeddings,
        },
      },
      {
        path: 'contributions',
        name: 'guest-contributions',
        components: {
          guest: GuestContributionsGiven,
        },
      },
      {
        path: 'invites',
        name: 'guest-invites',
        components: {
          guest: GuestInvites,
        },
      },
      {
        path: 'gifts',
        name: 'guest-gifts',
        components: {
          guest: GuestGiftsGiven,
        },
      },
    ],
  },
  {
    path: '/vendor',
    name: 'vendor',
    component: Vendor,
    meta: {
      requiresAuth: true,
      requiresVendor: true,
    },
    children: [
      {
        path: 'profile',
        name: 'vendor-profile',
        components: {
          vendor: VendorProfile,
        },
      },
      {
        path: 'wallet',
        name: 'vendor-wallet',
        components: {
          vendor: VendorWallet,
        },
      },
      {
        path: 'offerings',
        name: 'vendor-offerings',
        components: {
          vendor: VendorOfferings,
        },
      },
      {
        path: 'bookings',
        name: 'vendor-bookings',
        components: {
          vendor: VendorBookings,
        },
      },
      {
        path: 'upcoming',
        name: 'vendor-upcoming',
        components: {
          vendor: VendorUpcomingWeddings,
        },
      },
      {
        path: 'transactions',
        name: 'vendor-transactions',
        components: {
          vendor: VendorPaymentsReceived,
        },
      },
    ],
  },
  {
    path: '/vendors/categories',
    name: 'vendor-categories',
    component: VendorCategories,
  },
  {
    path: '/vendors/',
    name: 'vendors',
    component: Vendors,
  },
  {
    path: '/honeymoon/categories',
    name: 'honeymoon-categories',
    component: HoneymoonCategories,
  },
  {
    path: '/honeymoon-experiences',
    name: 'honeymoon',
    component: Honeymoon,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: FAQs,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresLoggedOut: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresLoggedOut: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      requiresLoggedOut: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Oops,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Nav Guards
router.beforeEach((to, from, next) => {
  const tkn = localStorage.getItem('_ikn');
  const id = localStorage.getItem('_ud');
  // If this an initial page load, start the route progress bar.
  if ((from.name === 'about' && to.name !== 'about') || (from.name === 'home' && to.name !== 'home')) {
    next(false);
    window.location = `/${to.name}`;
  }
  // if (from.name !== 'about' && to.name === 'about') window.location = '/about';
  // if (from.name !== 'home' && to.name === 'home') window.location = '/';
  if (to.name) {
    NProgress.start();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  // if (to.name) NProgress.start();
  if (to.meta.requiresLoggedOut && tkn) {
    next({
      path: '/',
    });
  } else if (to.meta.requiresAuth) {
    // if (store.getters.isAuth) {
    //   next();
    // } else {
    //   localStorage.removeItem('_ikn');
    //   localStorage.removeItem('_ud');
    //   localStorage.removeItem('_rlc');
    //   localStorage.removeItem('_rlg');
    //   localStorage.removeItem('_rlv');
    //   localStorage.removeItem('_acc');
    //   // window.location = '/login';
    //   next({
    //     path: '/login',
    //     replace: true,
    //     query: { redirect: to.fullPath },
    //   });
    // }
    axios.get(`http://localhost:3000/api/users/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    })
      .then((response) => {
        const { role } = response.data.user;
        if ((to.meta.requiresCouple && role.couple)
        || (to.meta.requiresGuest && role.guest) || (to.meta.requiresVendor && role.vendor)) {
          // console.log(store.getters.isAuth);
          // console.log(store.state.currentUser);
          // Proceed to account route
          next();
        }
      })
      .catch((error) => {
        console.log(error.response);
        localStorage.removeItem('_ikn');
        localStorage.removeItem('_ud');
        localStorage.removeItem('_rlc');
        localStorage.removeItem('_rlg');
        localStorage.removeItem('_rlv');
        localStorage.removeItem('_acc');

        // if (!localStorage.getItem('_ikn') && !localStorage.getItem('_ud') && !localStorage.getItem('_rlc') && !localStorage.getItem('_rlg') && localStorage.getItem('_rlv') && localStorage.getItem('_acc')) {
        window.location = '/login';
        //   next();
        // }
        // next({
        //   path: '/login',
        //   replace: true,
        //   query: { redirect: to.fullPath },
        // });
      });
  } else {
    // console.log(to.fullPath);
    next();
  }
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
  // setTimeout(() => NProgress.done(), 300);
});

// router.beforeEach((to, from, next) => {
// // Check for requiredAuth guard
//   if (to.match.some(record => record.meta.requiresAuth)) {
//     // Check if NOT logged in
//     if (!firebase.auth().currentUser) {
//       // Go to login page
//       next({
//         path: '/login',
//         query: { redirect: to.fullPath },
//       });
//     } else {
//       // Proceed to route
//       next();
//     }
//   } else {
//     // Proceed to route
//     next();
//   }
// });

export default router;
