<!-- eslint-disable max-len -->

<template>
  <div>
    <div class="pt-5"></div>
    <div class="container-fluid mt-6">
      <div class="row px-xl-3">
        <div class="col-sm-4 col-md-2 col-lg-2" style="background-color: #fff;">
          <div style="top: 120px;" class="sticky-top mb-5">
            <div class="sidebar-block" id="accordion" role="tablist">
              <!-- <h6 class="sidebar-heading pt-2">Get Started</h6> -->
              <div id="headingOne" role="tab" class="block-header"><strong><a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="accordion-link">Get Started</a></strong></div>
              <div id="collapseOne" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" class="collapse show ">
                <nav class="nav nav-pills flex-column mb-0 pb-0">
                  <a href="#launch" class="nav-link pl-4 mb-2 active " data-toggle="pill">Launch Wedding</a>
                  <a href="#bookings" class="nav-link pl-4 mb-2 " data-toggle="pill">Book Vendors </a>
                  <a href="#contributions" class="nav-link pl-4" data-toggle="pill">View Contributions</a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="col tab-content">
          <div class="tab-pane fade active show mb-4" id="launch">
            <div class="hero-content text-center mb-3">
              <h3 class="hero-heading pb-1">Launch Wedding</h3>
            </div>
            <div class="">
              <h5 class="text-uppercase mb-3" style="font-size: 0.9em;">Our Card</h5>
              <p class="mb-4">Upload save-the-date card or wedding launch card.</p>
              <div class="my-3"><input type="file" style="cotent: 'Upload Card'" name="uploadCard" class="btn btn-light border-md" @change="onFileSelected"></div>
              <!-- <button class="btn btn-outline-dark mr-3" type="button" name="button"><i class="fas fa-upload fa-lg"></i> Upload Card</button> -->
              <hr class="my-4">
            </div>
            <h5 class="text-uppercase mb-3" style="font-size: 0.9em;">Our Story</h5>
            <p class="mb-4">Add a short story of you two to kickstart your wedding fund.</p>
            <textarea name="name" rows="6" class="w-100"></textarea><br>
            <!-- <button class="btn btn-info mt-2" type="submit" name="button">Share <i class="fas fa-share fa-lg"></i></button> -->
            <div class="mt-3 d-flex justify-content-between mx-auto bg-light">
              <button class="btn btn-success" type="submit" name="button">Save <i class="fas fa-save fa-lg"></i></button>
              <button class="btn btn-info" type="button" name="button">Launch <svg class="svg-icon w-6 h-6"><use xlink:href="#checkmark-1"></use></svg></button>
            </div>
          </div>
          <div class="tab-pane fade" id="bookings">
            <div class="hero-content text-center mb-3">
              <h3 class="hero-heading">Vendor Bookings</h3>
            </div>
            <p class="text-md mb-4 pb-1">Select vendors for wedding and add them to your budget items to start booking them. </p>
            <div class="card mb-4">
              <table class="table table-borderless table-hover table-responsive-md">
                <thead class="bg-light">
                  <tr class="card-header">
                    <th class="text-uppercase text-sm">Item</th>
                    <th class="text-uppercase text-sm">Price</th>
                    <th class="text-uppercase text-sm">Amount Paid</th>
                    <th class="text-uppercase text-sm">Status</th>
                    <th class="text-uppercase text-sm">Vendor</th>
                    <th class="text-uppercase text-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle">Venue</td>
                    <td class="align-middle">$4000</td>
                    <td class="align-middle">$2400</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-info p-2">60% Paid</span></td>
                    <td class="align-middle">Speke Resort</td>
                    <td class="align-middle"><a href="/checkout" class="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#exampleModal">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Decorations</td>
                    <td class="align-middle">$2500</td>
                    <td class="align-middle">$1200</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-warning p-2">40% Paid</span></td>
                    <td class="align-middle">Florify</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Videography</td>
                    <td class="align-middle">$700</td>
                    <td class="align-middle">$700</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-success p-2">100% Paid</span></td>
                    <td class="align-middle">VidGenius</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Photography</td>
                    <td class="align-middle">$300</td>
                    <td class="align-middle">$0</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-danger p-2">0% Paid</span></td>
                    <td class="align-middle">PhotoClick</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Cake</td>
                    <td class="align-middle">$800</td>
                    <td class="align-middle">$480</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-info p-2">60% Paid</span></td>
                    <td class="align-middle">Jenny's Bakery</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Food</td>
                    <td class="align-middle">$2000</td>
                    <td class="align-middle">$800</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-warning p-2">40% Paid</span></td>
                    <td class="align-middle">FoodHub</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Bridal Wear</td>
                    <td class="align-middle">$3000</td>
                    <td class="align-middle">$3000</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-success p-2">100% Paid</span></td>
                    <td class="align-middle">DreamBride</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Groom's Wear</td>
                    <td class="align-middle">$1000</td>
                    <td class="align-middle">$1000</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-success p-2">100% Paid</span></td>
                    <td class="align-middle">RealMen</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Rings</td>
                    <td class="align-middle">Covered</td>
                    <td class="align-middle">Covered</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-success p-2">Covered</span></td>
                    <td class="align-middle">Royal Jewellery</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Salon</td>
                    <td class="align-middle">$1500</td>
                    <td class="align-middle">$1200</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-info p-2">80% Paid</span></td>
                    <td class="align-middle">UniSalon</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <td class="align-middle">Transportation</td>
                    <td class="align-middle">$600</td>
                    <td class="align-middle">$120</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-warning p-2">20% Paid</span></td>
                    <td class="align-middle">Florify</td>
                    <td class="align-middle"><a href="customer-order.html" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="contributions">
            <div class="hero-content text-center mb-4">
              <h3 class="hero-heading">Contributions Received</h3>
            </div>
            <div class="card mb-4">
              <table class="table table-borderless table-hover table-responsive-md">
                <thead class="bg-light">
                  <tr>
                    <th class="text-uppercase text-sm"></th>
                    <th class="text-uppercase text-sm">Name</th>
                    <th class="text-uppercase text-sm">Amount</th>
                    <th class="text-uppercase text-sm">Date</th>
                    <th class="text-uppercase text-sm">Status</th>
                    <th class="text-uppercase text-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="align-middle"><img src="https://variety.com/wp-content/uploads/2016/03/kevin-hart-vod-service.jpg?w=681&h=383&crop=1" class="budget-img"/></th>
                    <td class="align-middle">Kevin</td>
                    <td class="align-middle">$2400</td>
                    <td class="align-middle">14/08/2019</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-warning p-2">Pledge</span></td>
                    <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <th class="align-middle"><img src="https://careerswithstem.com.au/wp-content/uploads/2019/05/Sophie-Ritchie-profile-367x367.jpg" class="budget-img"/></th>
                    <td class="align-middle">Sarah</td>
                    <td class="align-middle">$300</td>
                    <td class="align-middle">14/08/2019</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-info p-2">Cash</span></td>
                    <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <th class="align-middle"><img src="https://www.houseofsmiles.net/files/2016/12/Missing-Teeth-More-than-Just-255x300.jpg" class="budget-img"/></th>
                    <td class="align-middle">Tom</td>
                    <td class="align-middle">$250</td>
                    <td class="align-middle">14/08/2019</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-success p-2">iWedhud Credits</span></td>
                    <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                  <tr>
                    <th class="align-middle"><img src="https://www.augusta.edu/faculty/directory/images/faculty/cabest.jpg" class="budget-img"/></th>
                    <td class="align-middle">Jane</td>
                    <td class="align-middle">$150</td>
                    <td class="align-middle">14/08/2019</td>
                    <td class="align-middle"><span class="badge text-uppercase badge-success p-2">iWedhud Credits</span></td>
                    <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm">View</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Quickview Modal    -->
    <div id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true" class="modal fade quickview">
      <div role="document" class="modal-dialog modal-lg">
        <div class="modal-content">
          <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close">
            <svg class="svg-icon w-100 h-100 svg-icon-light align-middle">
              <use xlink:href="#close-1"> </use>
            </svg>
          </button>
          <div class="modal-body">
            <!-- <div class="ribbon ribbon-primary">Sale</div> -->
            <div class="row">
              <div class="col-lg-6">
                <div data-slider-id="1" class="owl-carousel owl-theme owl-dots-modern detail-full">
                  <div style="background: center center url('images/venues.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues1.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues2.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues3.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues4.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div>
                  <h2 class="mb-4 mt-4 mt-lg-1">Speke Resort</h2>
                  <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                    <ul class="list-inline mb-2 mb-sm-0">
                      <li class="list-inline-item h4 font-weight-light mb-0">$3600.00</li>
                      <li class="list-inline-item text-muted font-weight-light">
                        <del>$4000.00</del>
                      </li>
                    </ul>
                    <div class="d-flex align-items-center">
                      <ul class="list-inline mr-2 mb-0">
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-gray-300"></i></li>
                      </ul><span class="text-muted text-uppercase text-sm">25 reviews</span>
                    </div>
                  </div>
                  <p class="mb-4 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                  <form action="/book" method="post">
                    <div class="row">
                      <div class="col-sm-6 col-lg-12 detail-option mb-3">
                        <h6 class="detail-option-heading">Size <span>(required)</span></h6>
                        <label for="size_0" class="btn btn-sm btn-outline-secondary detail-option-btn-label">

                          300
                          <input type="radio" name="size" value="value_0" id="size_0" required class="input-invisible">
                        </label>
                        <label for="size_1" class="btn btn-sm btn-outline-secondary detail-option-btn-label">

                          1000
                          <input type="radio" name="size" value="value_1" id="size_1" required class="input-invisible">
                        </label>
                        <label for="size_2" class="btn btn-sm btn-outline-secondary detail-option-btn-label">

                          2500
                          <input type="radio" name="size" value="value_2" id="size_2" required class="input-invisible">
                        </label>
                      </div>
                      <div class="col-sm-6 col-lg-12 detail-option mb-3">
                        <h6 class="detail-option-heading">Type <span>(required)</span></h6>
                        <label for="material_0" class="btn btn-sm btn-outline-secondary detail-option-btn-label">

                          Indoor
                          <input type="radio" name="material" value="value_0" id="material_0" required class="input-invisible">
                        </label>
                        <label for="material_1" class="btn btn-sm btn-outline-secondary detail-option-btn-label">

                          Outdoor
                          <input type="radio" name="material" value="value_1" id="material_1" required class="input-invisible">
                        </label>
                      </div>
                      <div class="col-12 col-lg-6 detail-option mb-5">
                        <label class="detail-option-heading font-weight-bold">Items <span>(required)</span></label>
                        <input name="items" type="number" value="1" class="form-control detail-quantity">
                      </div>
                    </div>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <button type="submit" class="btn btn-dark btn-lg mb-1"> <i class="fa fa-shopping-cart mr-2"></i>Book</button>
                      </li>
                      <li class="list-inline-item"><a href="#" class="btn btn-outline-secondary mb-1">Replace</a></li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bride-n-groom-account',
  data: () => ({
  }),
  methods: {
    onFileSelected(event) {
      console.log(event);
    },
  },
};
</script>

<style scoped>

</style>
