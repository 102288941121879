<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-4">
      <h3 class="hero-heading">Payments Made</h3>
    </div>
    <payments-made-component/>
  </div>
</template>

<script>
import PaymentsMadeComponent from '../PaymentsMadeComponent.vue';

export default {
  name: 'PaymentsReceived',
  components: { PaymentsMadeComponent },
  props: {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
