<!-- eslint-disable max-len -->

<template>
  <div class="products-grid order-lg-2" >
    <div class="hero-content text-center mb-3">
      <h3 class="hero-heading">Upcoming Weddings</h3>
    </div>
    <upcoming-weddings-component />
  </div>
</template>
<script>
import UpcomingWeddingsComponent from '../UpcomingWeddingsComponent.vue';

export default {
  components: { UpcomingWeddingsComponent },
  name: 'UpcomingWeddings',
  data: () => ({
  }),
};
</script>
<style scoped>
.upcoming-card{
  object-fit: cover;
  height: 5.4rem;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
}
.border-edges{
  border-radius: 0.5rem 0.5rem;
}
</style>
