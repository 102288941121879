<!-- eslint-disable max-len -->

<template>
  <div class="products-grid order-lg-2 px-5 mt-6">
    <div class="hero-content text-center mb-4 pt-5">
      <h3 class="hero-heading">Honeymoon Experiences</h3>
    </div>
    <header class="product-grid-header">
      <div class="mr-3 mb-3">
        Showing <strong>1-12 </strong>of <strong>12 </strong>Categories
      </div>
      <div class="mr-3 mb-3"><span class="mr-2">Show</span><a href="#" class="product-grid-header-show active">12    </a><a href="#" class="product-grid-header-show ">24    </a><a href="#" class="product-grid-header-show ">All    </a>
      </div>
      <div class="mb-3 d-flex align-items-center"><span class="d-inline-block mr-1">Sort by</span>
        <select class="custom-select w-auto border-0">
          <option value="orderby_0">Default</option>
          <option value="orderby_1">Popularity</option>
          <option value="orderby_2">Rating</option>
          <option value="orderby_3">Newest first</option>
        </select>
      </div>
    </header>
    <div class="row">
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://i.pinimg.com/originals/79/56/f6/7956f69912d7021d67c5c910e3cf3f61.png" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Swimming, Scobber Diving, Water Rafting</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Bora Bora</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://i.pinimg.com/564x/fb/38/bb/fb38bb7a182551ac2d6f5110f4441d4a.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Swimming, Underwater Rooms, Water Rafting</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Maldives</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.topinspired.com/wp-content/uploads/2014/01/hawaii.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Paragliding, Beach, Surfing, Water Rafting</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Hawaii</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://i.pinimg.com/736x/2e/16/63/2e1663ac89b69a205319420b1ab405e1.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Ocean, Beach, Surfing, Water Rafting</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">St. Lucia</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.topinspired.com/wp-content/uploads/2014/01/paris1.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Roller Coasters, Skydiving</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Paris</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.amlu.com/wp-content/uploads/2017/06/a-look-at-the-fiji-honeymoon-haven-of-snapchat-ceo-evan-spiegel-and-new-wife-miranda-kerr5.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Swimming, Beach</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Fiji</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.topinspired.com/wp-content/uploads/2014/01/Chiang-Mai-Yii-Peng-Festival-Thailand-02.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Festivals, Thailand</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Chiang Mai</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.topinspired.com/wp-content/uploads/2014/01/cinque-terre-italy.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Paragliding, Italy</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Cinque Terre</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.topinspired.com/wp-content/uploads/2014/01/Colombia.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Nature, Green Forests</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Colombia</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://www.manhattanbride.com/wp-content/uploads/2014/09/Scrub-Island-1.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Yacht Tour, Beach, Islands</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">BVI</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://images.thrillophilia.com/image/upload/s--BWWGZJ7G--/c_fill,f_auto,fl_strip_profile,h_775,q_auto,w_1600/v1/images/photos/000/115/473/original/1508153927_gallery-3-new.jpg.jpg?1508153927" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Lagoons, Reefs, Beach, Ocean</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Mauritius</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
      <!-- category-->
      <div class="col-xl-2 col-sm-6">
        <div class="product">
          <div class="product-image">
            <img src="https://southerntravelagency.com/wp-content/uploads/2017/04/honeymoon-tahiti.jpg" alt="product" class="img-fluid" style=" object-fit: cover;"/>
            <div class="product-hover-overlay">
              <a href="#" class="product-hover-overlay-link"></a>
              <div class="product-hover-overlay-buttons">
                <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
              </div>
            </div>
          </div>
          <div class="py-2">
            <p class="text-muted text-sm mb-1">Site Seeing, Underwater, Beach, Ocean</p>
            <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">Tahiti</a></h3><span class="text-muted">$600 - $30,000</span>
          </div>
        </div>
      </div>
      <!-- /category-->
    </div>
    <nav aria-label="page navigation" class="d-flex justify-content-center mb-5 mt-3">
      <ul class="pagination">
        <li class="page-item"><a href="#" aria-label="Previous" class="page-link"><span aria-hidden="true">Prev</span><span class="sr-only">Previous</span></a></li>
        <li class="page-item active"><a href="#" class="page-link">1       </a></li>
        <li class="page-item"><a href="#" class="page-link">2       </a></li>
        <li class="page-item"><a href="#" aria-label="Next" class="page-link"><span aria-hidden="true">Next</span><span class="sr-only">Next     </span></a></li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'honeymoon',
  data: () => ({

  }),
};
</script>
<style scoped>
</style>
