<template>
  <!-- eslint-disable max-len -->
  <div class="card mb-5 shadow-sm">
    <table class="table table-borderless table-hover table-responsive-md">
      <thead class="bg-light">
        <tr>
          <!-- <th class="text-uppercase text-sm"></th> -->
          <th class="text-uppercase text-sm">Ref</th>
          <th class="text-uppercase text-sm">Couple</th>
          <th class="text-uppercase text-sm">Amount</th>
          <th class="text-uppercase text-sm">Date</th>
          <!-- <th class="text-uppercase text-sm">Status</th> -->
          <th class="text-uppercase text-sm">Contact</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c,i) in user.contributions_given" :key="i">
          <!-- <th class="align-middle"><img :src="c.couple_img_url" class="budget-img"/></th> -->
          <td class="align-middle">#00{{i+1}}</td>
          <td class="align-middle">
            <router-link v-if="c.receiver.username&&c.receiver.account" :to="`/${c.receiver.account.toLowerCase()}s/${c.receiver.username}`" class="text-info text-sm">{{c.receiver.couple_fullname}}</router-link>
          </td>
          <td class="align-middle">{{c.amount.currency.split('- ')[1]}} {{c.amount.given.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td>
          <td class="align-middle">{{c.date.split('T')[0]}}</td>
          <!-- <td class="align-middle"><span class="badge text-uppercase p-2" :class="c.status === 'Pledge' ? 'badge-warning' : (c.status === 'Cash' ? 'badge-info' : 'badge-success')">{{c.status}}</span></td> -->
          <td class="align-middle">{{c.receiver.country.name}}, {{c.receiver.phone}}, {{c.receiver.email}}</td>
        </tr>
      </tbody>
      <tbody v-if="this.user.contributions_given.length === 0">
        <td class="align-middle text-muted">No contributions given yet</td>
      </tbody>
      <tfoot v-if="this.user.contributions_given.length !== 0">
        <tr>
          <td class="align-middle text-uppercase text-sm"><strong>Total</strong></td>
          <td></td>
          <td class="align-middle"><strong>{{ttl}}</strong></td>
          <td></td>
          <!-- <td></td> -->
          <!-- <td></td> -->
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ContributionsGivenComponent',
  props: {
  },
  data() {
    return {
      country: '',
      currency: '',
      ttl: '',
    };
  },
  computed: {
    ...mapState(['ContributionsGiven', 'Countries']),
    ...mapGetters(['user', 'countries']),
    total() {
      let t = 0;
      const currency = [];
      this.user.contributions_given.forEach((c) => {
        t += c.amount.given;
        currency.push(c.amount.currency);
      });
      return `${currency[0].split('- ')[1]} ${t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
  },
  methods: {
    getCC() {
      const ctry = this.Countries.filter((country) => country.name === this.user.address.country);
      // console.log(ctry[0]);
      if (ctry[0]) {
        this.country = ctry[0].name;
        [this.currency] = ctry[0].currencies;
      }
      // console.log({ country: this.country, currency: this.currency });
    },
    getTotal() {
      let t = 0;
      if (this.user.contributions_given.length === 1) {
        t = this.user.contributions_given[0].amount.given;
        const c = this.user.contributions_given[0].amount.currency;
        this.ttl = `${c.split('- ')[1]} ${t.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      } else {
        this.user.contributions_given.forEach((cg) => {
          // console.log(this.contributions_given[0]);
          const fromC = cg.amount.currency.split('- ')[1].toLowerCase();
          const toC = this.currency.toLowerCase();
          axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${fromC}/${toC}.json`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              // console.log(response.data);
              const rate = response.data[toC];
              // console.log(cg.amount.given * rate);
              t += (cg.amount.given * rate);
              // console.log(t);
              this.ttl = `${this.currency} ${t.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
              // console.log({ total_contributions_given: this.ttl });
            })
            .catch((err) => {
              console.log(err.response);
            });
        });
      }
    },
  },
  created() {
    this.getCC();
    this.getTotal();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
