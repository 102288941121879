<template>
  <!-- eslint-disable max-len -->
  <div class="footer-iwh">
    <!-- Footer-->
      <footer class="main-footer">
        <!-- Services block-->
        <div class="bg-gray-100 text-dark-700 py-6">
          <div class="container">
            <div class="row">
              <!-- <div class="col-lg-4 service-column">
                <svg class="svg-icon service-icon">
                  <use xlink:href="#delivery-time-1"> </use>
                </svg>
                <div class="service-text">
                  <h6 class="text-uppercase">Free shipping &amp; return</h6>
                  <p class="text-muted font-weight-light text-sm mb-0">Free Shipping over $300</p>
                </div>
              </div> -->
              <div class="col-lg-6 service-column">
                <svg viewBox="0 0 64 64" class="svg-icon service-icon"><path stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" d="M42 22a20 20 0 11-20 20" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/><circle stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" r="20" cy="22.001" cx="22" data-name="layer2" stroke-linejoin="round" stroke-linecap="round"/><path stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" d="M26.949 17a5.006 5.006 0 00-4.932-4.167c-2.762 0-5 1.822-5 4.583s2.5 4.012 5 4.584 5 1.822 5 4.584-2.238 4.584-5 4.584a5 5 0 01-5-5" stroke-linejoin="round" stroke-linecap="round"/><path d="M22.018 12.832v-2.831m0 23.998v-2.832" stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" data-name="layer2" stroke-linejoin="round" stroke-linecap="round"/><path d="M42 53.999v-2.834" stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/><path stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" d="M37 46.165a5 5 0 005 5c2.762 0 5-1.822 5-4.582S44.5 42.562 42 42a9.814 9.814 0 01-2.887-1.1" stroke-linejoin="round" stroke-linecap="round"/><path stroke-miterlimit="10" stroke="var(--layer2, #202020)" fill="none" d="M46.93 37a4.993 4.993 0 00-1.535-2.835" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/></svg>
                <div class="service-text">
                  <h6 class="text-uppercase">Money back guarantee</h6>
                  <p class="text-muted font-weight-light text-sm mb-0">30 Days Money
                    Back Guarantee</p>
                </div>
              </div>
              <div class="col-lg-6 service-column">
                <svg class="svg-icon service-icon" viewBox="0 0 64 64"><path data-name="layer2" d="M24 40.5V44c0 2 .6 5-10.4 7A14.6 14.6 0 002 62h59a14.3 14.3 0 00-11.4-11C39 49 40 46 40 44v-5" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer1" d="M46.4 33H51a3.3 3.3 0 003-3v-7a3.3 3.3 0 00-3-3h-5m-28 0h-6a3.3 3.3 0 00-3 3v7a3.3 3.3 0 003 3h5.2" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer2" d="M46 18C26 18 26 4 26 4s1 12-8 15m18 43l9.6-12m-27.7 0L28 62M17.9 50a10.8 10.8 0 01-4.9-4c4-4 5-20 5-20m28 0s0 16 4 20a10.7 10.7 0 01-4.7 4" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer2" d="M26 42c-4.7-2.9-8-8.8-8-16V16C18 5.6 25.4 2 32 2s14 3.6 14 14v16c0 4-4 7-6 7" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer1" d="M40 38.9a2.9 2.9 0 00-2.9-2.9h-2.2a2.9 2.9 0 00-2.9 2.9 3 3 0 002.9 3.1H37a3 3 0 003-3.1z" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>
                <div class="service-text">
                  <h6 class="text-uppercase">+256-773-08-7272</h6>
                  <p class="text-muted font-weight-light text-sm mb-0">24/7 Available
                    Support</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Main block - menus, subscribe form-->
        <div class="py-6 bg-gray-300 text-muted">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 mb-5 mb-lg-0">
                <div class="font-weight-bold text-lg text-dark mb-3">iWedhub<span class="text-primary">.</span></div>
                <p>Dream weddings realized effortlessly.</p>
                <ul class="list-inline">
                  <li class="list-inline-item"><a href="https://www.twitter.com/iwedhub" target="_blank" title="twitter" class="text-muted text-hover-primary"><i class="fab fa-twitter"></i></a></li>
                  <li class="list-inline-item"><a href="https://www.facebook.com/iwedhub" target="_blank" title="facebook" class="text-muted text-hover-primary"><i class="fab fa-facebook"></i></a></li>
                  <li class="list-inline-item"><a href="https://www.instagram.com/iwedhub" target="_blank" title="instagram" class="text-muted text-hover-primary"><i class="fab fa-instagram"></i></a></li>
                  <li class="list-inline-item"><a href="https://www.pinterest.com/iwedhub" target="_blank" title="pinterest" class="text-muted text-hover-primary"><i class="fab fa-pinterest"></i></a></li>
                  <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCZ06_ZuVMvJDgcbtlHkQg2Q" target="_blank" title="youtube" class="text-muted text-hover-primary"><i class="fab fa-youtube"></i></a></li>
                </ul>
              </div>
              <div class="col-lg-2 col-md-6 mb-5 mb-lg-0">
                <h6 class="text-uppercase text-dark mb-3">Discover</h6>
                <ul class="list-unstyled">
                  <li> <a href="#" class="text-muted">Shop</a></li>
                  <!-- <li> <a href="#" class="text-muted">For Brides</a></li>
                  <li> <a href="#" class="text-muted">For Grooms</a></li> -->
                  <li> <a href="#" class="text-muted">Engagement</a></li>
                  <li> <a href="/honeymoon-experiences" class="text-muted">Honeymoon</a></li>
                  <li> <a href="/vendors" class="text-muted">Vendors</a></li>
                  <li> <a href="#" class="text-muted">Blog</a></li>
                </ul>
              </div>
              <div class="col-lg-2 col-md-6 mb-5 mb-lg-0">
                <h6 class="text-uppercase text-dark mb-3">Company</h6>
                <ul class="list-unstyled">
                  <li> <router-link to="#" class="text-muted">Terms of Service</router-link></li>
                  <li> <router-link to="/faqs" class="text-muted">FAQs</router-link></li>
                  <li> <a href="/about" class="text-muted">About Us</a></li>
                  <li> <router-link to="/contact" class="text-muted">Contact</router-link></li>
                  <li> <router-link to="#" class="text-muted">Discover</router-link></li>
                </ul>
              </div>
              <div class="col-lg-4">
                <h6 class="text-uppercase text-dark mb-3">Download the free iWedhub App</h6>
                <p class="mb-3">
                  <a href="#"><img :src="require(`../assets/badges/app-store-badge.svg`)" alt="" class="mb-2 mr-5"></a>
                  <a href="#"><img :src="require(`../assets/badges/google-play-badge.svg`)" alt="" class="mb-2"></a>
                </p>
                <p class="mb-3"> Sign up to our newsletter and be the first to know of the latest offers.</p>
                <form @submit="newsLetterSignUp" id="newsletter-form">
                  <div class="input-group mb-3">
                    <input type="email" placeholder="Your Email Address" v-model="email" required class="newsletter-input form-control bg-transparent border-secondary border-right-0">
                    <div class="input-group-append">
                      <button type="submit" class="newsletter-btn btn btn-outline-secondary border-left-0"> <i class="fa fa-paper-plane text-lg text-dark"></i></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Copyright section of the footer-->
        <div class="py-4 font-weight-light bg-gray-800 text-gray-300">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 text-center text-md-left">
                <p class="mb-md-0">&copy; {{year}} iWedhub.  All rights reserved.</p>
              </div>
              <div class="col-md-6">
                <ul class="list-inline mb-0 mt-2 mt-md-0 text-center text-md-right">
                  <li class="list-inline-item"><img :src="require(`../assets/visa.svg`)" alt="..." class="w-2rem"></li>
                  <li class="list-inline-item"><img :src="require(`../assets/mastercard.svg`)" alt="..." class="w-2rem"></li>
                  <li class="list-inline-item"><img :src="require(`../assets/paypal.svg`)" alt="..." class="w-2rem"></li>
                  <!-- <li class="list-inline-item"><img :src="require(`../assets/western-union.svg`)" alt="..." class="w-2rem"></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    <!-- /Footer end-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'Footer',
  props: {
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters(['surl']),
    year() {
      const d = new Date();
      return d.getFullYear();
    },
  },
  methods: {
    newsLetterSignUp(e) {
      e.preventDefault();
      // eslint-disable-next-line
      // alert(`Thank you, ${this.email}, for signing to our newsletter!`)
      axios.post(`${this.surl}/api/users/newsletter/signup`, {
        subscriberEmail: this.email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response.data);
          /* eslint-disable no-alert */
          alert(response.data.message);
          this.email = '';
        })
        .catch((err) => {
          console.log(err.response.data);
          // alert(response.data.message,data);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.newsletter-input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.newsletter-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>
