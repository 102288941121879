<template>
  <!-- eslint-disable max-len -->
  <div class="card mb-5 shadow-sm">
    <table class="table table-borderless table-hover table-responsive-md">
      <thead class="bg-light">
        <tr>
          <th class="text-uppercase text-sm"></th>
          <th class="text-uppercase text-sm">Couple</th>
          <th class="text-uppercase text-sm">Date</th>
          <th class="text-uppercase text-sm">Item</th>
          <th class="text-uppercase text-sm">Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(g,i) in GiftsGiven" :key="i">
          <td class="align-middle"><img :src="g.couple_img_url" class="budget-img"/></td>
          <td class="align-middle">{{i+1}}. {{g.couple}}</td>
          <td class="align-middle">{{g.date}}</td>
          <td class="align-middle">{{g.item}}</td>
          <td class="align-middle">{{g.type}}</td>
          <td class="align-middle"><a :href="g.vendor_url" class="btn btn-outline-dark btn-sm shadow-sm" data-toggle="modal" :data-target="'#GiftGivenModal'+i">View</a></td>
        </tr>
      </tbody>
      <tbody v-if="GiftsGiven.length === 0">
        <td class="align-middle text-muted">No gifts given yet</td>
      </tbody>
    </table>
    <button class="btn btn-outline-light btn-sm text-lg text-dark">+</button>
    <!-- Quickview Modal -->
    <div v-for="(g, i) in GiftsGiven" :key="i" :id="'GiftGivenModal'+i" tabindex="-1" role="dialog" aria-hidden="true" class="modal fade quickview">
      <div role="document" class="modal-dialog modal-lg">
        <div class="modal-content">
          <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close">
            <svg class="svg-icon w-100 h-100 svg-icon-light align-middle">
              <use xlink:href="#close-1"> </use>
            </svg>
          </button>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6">
                <div data-slider-id="1" class="owl-carousel owl-theme owl-dots-modern detail-full">
                  <div style="background: center center url('images/venues.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues1.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues2.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues3.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues4.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div>
                  <h2 class="mb-4 mt-4 mt-lg-1">{{g.item}}</h2>
                  <p class="text-muted">{{g.type}}</p>
                  <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                    <div class="d-flex align-items-center">
                      <ul class="list-inline mb-2 mb-sm-0">
                        <li class="list-inline-item h4 font-weight-light mb-0">${{g.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</li>
                      </ul>
                      <ul class="list-inline mr-2 mb-0">
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-gray-300"></i></li>
                      </ul><span class="text-muted text-uppercase text-sm">25 reviews</span>
                    </div>
                  </div>
                </div>
                <ul class="">
                  <li class="list-inline-item">
                    <button type="submit" class="btn btn-dark btn-lg mb-1"> <i class="fa fa-shopping-cart mr-2"></i>Claim</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GiftsGivenComponent',
  props: {
  },
  data: () => ({
  }),
  computed: {
    ...mapState(['GiftsGiven']),
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
