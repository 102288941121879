<template>
  <!-- eslint-disable max-len -->
  <div class="top-bar">
    <div class="container-fluid">
      <div class="row d-flex align-items-center">
        <div class="col-sm-7 d-none d-sm-block">
          <ul class="list-inline mb-0">
            <li class="list-inline-item pr-3 mr-0">
              <svg class="svg-icon" viewBox="0 0 64 64"><path data-name="layer1" d="M58.9 47l-10.4-6.8a4.8 4.8 0 00-6.5 1.3c-2.4 2.9-5.3 7.7-16.2-3.2S19.6 24.4 22.5 22a4.8 4.8 0 001.3-6.5L17 5.1c-.9-1.3-2.1-3.4-4.9-3S2 6.6 2 15.6s7.1 20 16.8 29.7S39.5 62 48.4 62s13.2-8 13.5-10-1.7-4.1-3-5z" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>{{iwh_contact}}
            </li>
            <li class="list-inline-item px-3 border-left d-none d-lg-inline-block">
              From yes to I do!</li>
          </ul>
        </div>
        <div class="col-sm-5 d-flex justify-content-end">
          <!-- Language Dropdown-->
          <div class="dropdown border-right px-3">
            <a id="langsDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle topbar-link">
              <img :src="require(`../assets/united-kingdom.svg`)" alt="english" class="topbar-flag">English
            </a>
            <div aria-labelledby="langsDropdown" class="dropdown-menu dropdown-menu-right">
              <a href="#" class="dropdown-item text-sm"><img :src="require(`../assets/germany.svg`)" alt="german" class="topbar-flag">German</a>
              <a href="#" class="dropdown-item text-sm"><img :src="require(`../assets/france.svg`)" alt="french" class="topbar-flag">French</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'TopBar',
  data: () => ({
  }),
  computed: {
    ...mapState(['iwh_contact']),
  },
};
</script>
