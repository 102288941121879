<!-- eslint-disable max-len -->
<template>
  <div class="px-lg-5 px-xl-6">
    <div class="hero-content mb-4">
      <h3 class="hero-heading text-center">Profile</h3>
      <transition name="fade">
        <p v-if="message.error" class="px-3 py-2 alert alert-danger mb-3">{{message.error}}</p>
      </transition>
      <transition name="fade">
        <p v-if="message.success" class="px-3 py-2 alert alert-success mb-3">{{message.success}}</p>
      </transition>
    </div>
    <div class="customer-sidebar card border-0" v-if="!edit_profile&&user" style="max-width: 100%;">
      <div class="customer-profile">
        <a class="d-inline-block" href="#">
          <img class="profile-img p-2 mb-4" :src="`http://localhost:3000/images/${user.profile_img_url}`">
        </a>
        <h5>{{user.full_name}}</h5>
        <router-link class="text-muted text-sm mb-0" :to="`/guests/${user.username}`">@{{user.username}}
          <svg v-if="user.verified" class="svg-icon h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>
          <!-- <svg width="24" height="24"><path fill="#00A3FF" d="M10.081 3.724c.804-1.56 3.034-1.56 3.838 0a2.158 2.158 0 002.576 1.067c1.671-.534 3.248 1.043 2.714 2.714a2.158 2.158 0 001.067 2.576c1.56.804 1.56 3.034 0 3.838a2.158 2.158 0 00-1.067 2.576c.534 1.671-1.043 3.248-2.714 2.714a2.158 2.158 0 00-2.576 1.067c-.804 1.56-3.034 1.56-3.838 0a2.158 2.158 0 00-2.576-1.067c-1.671.534-3.248-1.043-2.714-2.714a2.158 2.158 0 00-1.067-2.576c-1.56-.804-1.56-3.034 0-3.838a2.158 2.158 0 001.067-2.576C4.257 5.834 5.834 4.257 7.505 4.79a2.158 2.158 0 002.576-1.067z"/><path class="permanent" d="M14.856 9.19c.205-.24.521-.252.761-.047.241.204.206.521.001.762l-3.755 4.75a.571.571 0 01-.815.058l-2.571-2.286a.571.571 0 11.759-.854l2.135 1.897 3.485-4.28z" fill="#fff"/></svg> -->
        </router-link>
      </div>
      <div class="list-group customer-nav">
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <span class="font-weight-bold"><i class="far fa-calendar ml-1 mr-sm-2 mr-1 d-none d-sm-inline-block"></i>Birthday</span>
          <span class="ml-6" v-if="user.birthday">{{user.birthday.split('T')[0]}}</span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">
            <svg viewBox="0 0 64 64" class="svg-icon mr-sm-2 mr-1 d-none d-sm-inline-block" style=""><path d="M38.1 46H52l8 16H4l8-16h13.9" stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" data-name="layer2" stroke-linejoin="round" stroke-linecap="round"/><path stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" d="M32 2a18.1 18.1 0 00-18.1 18.1C13.9 36.4 32 52.4 32 52.4s18.1-16 18.1-32.3A18.1 18.1 0 0032 2z" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/><circle stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" cy="20" cx="32" data-name="layer1" stroke-linejoin="round" stroke-linecap="round" r="6"/></svg>Address
          </span>
          <span class="ml-6">{{user.address.full}}</span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">
            <svg viewBox="0 0 64 64" class="svg-icon mr-sm-2 mr-1 d-none d-sm-inline-block"><path stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" d="M57.7 16.5A30 30 0 1143.4 4.3" data-name="layer2" stroke-linejoin="round" stroke-linecap="round"/><path stroke-miterlimit="10" stroke="var(--layer1, #202020)" fill="none" d="M32 12c0 4.2 6 1.7 6 6s-4.6 7.3-8 5-7.9-3.5-11.9 2.1-1.3 12 1.5 11.9 5.5-2.8 6.7.6 1.5 3.4 2.8 4.2 1.3 2.2.9 4.1 2 8 4 8 3.8-.8 4-4 2.6-3.3 3.8-4.2-.9-4.3 1.3-6.5 6.6-6.2 2.8-7.2-3.5-1.8-4-3.4-2-3.2 1-3.3a11.9 11.9 0 008.7-3.6c2.5-2.6 3.8-5.2 6.1-5.2A25.6 25.6 0 0051.2 9a30 30 0 00-7.8-4.7C36.7 7.5 32 7.8 32 12z" data-name="layer1" stroke-linejoin="round" stroke-linecap="round"/></svg>Country
          </span>
          <span class="ml-6">{{user.address.country}}</span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">
            <svg class="svg-icon mr-sm-2 mr-1 d-none d-sm-inline-block" viewBox="0 0 64 64"><path data-name="layer2" d="M52 5a3 3 0 00-3-3H15a3 3 0 00-3 3v5h40zm0 45V10m-40 0v40m0 0v9a3 3 0 003 3h34a3 3 0 003-3v-9z" fill="none" stroke="var(--layer1, #202020)" stroke-linecap="round" stroke-linejoin="round"/><path data-name="layer1" fill="none" stroke="var(--layer1, #202020)" stroke-linecap="round" stroke-linejoin="round" d="M30 56h4"/></svg>Phone <span class="d-none d-sm-inline-block">No.</span></span>
          <span class="ml-6">{{user.contact.mobilephone}}</span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center profile-card-bottom">
          <span class="font-weight-bold">
            <svg class="svg-icon mr-sm-2 mr-1 d-none d-sm-inline-block" viewBox="0 0 64 64"><path data-name="layer2" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M2 12l30 27.4L62 12" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer1" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M2 12h60v40H2z" stroke-linejoin="round" stroke-linecap="round"/></svg>Email
          </span>
          <span class="ml-6">{{user.email}}</span>
        </div>
      </div>
      <div class="text-center my-4">
        <button type="submit" class="btn btn-outline-dark" @click="editProfile">
          <i class="far fa-edit mr-2"></i>Edit Profile
        </button>
      </div>
    </div>
    <div v-if="edit_profile || !user">
      <p class="mb-3">Upload profile image and fill in personal details to complete your profile.</p>
      <div class="block-header"><strong class="text-uppercase">Profile Image</strong></div>
      <div class="block-body">
        <div class="mb-1">
          <div class="text-center">
            <img v-if="user.profile_img_url" class="profile-img p-2 mb-4" :src="`http://localhost:3000/images/${user.profile_img_url}`"><br>
            <button v-if="!change_profile_img" @click="change_profile_img = true" class="btn btn-outline-dark mr-3" type="button" name="button"><i class="far fa-edit fa-lg"></i>Change Profile Image</button>
            <div v-if="change_profile_img">
              <div class="mb-4"><input type="file" ref="profileimg" name="profileimg" class="btn btn-light border-md mx-auto" @change="onImgSelected"></div>
              <button class="btn btn-outline-dark mr-3" type="button" name="button" @click="uploadProfileImg"><i class="fas fa-upload fa-lg"></i>Upload <span v-if="profile_img_url">New</span> Profile Image</button>
            </div>
           </div>
        </div>
      </div>
      <div class="block-header"><strong class="text-uppercase">Personal details</strong></div>
      <div class="block-body">
        <form @submit.prevent="updateGuestProfile">
          <!-- guest profile row -->
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="fullname" class="form-label">Full Name<span class="text-primary">*</span></label>
                <input id="fullname" v-model="fullname" type="text" class="form-control" required>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="other_guests" class="form-label">Other Guest(s)</label>
                <input id="other_guests" v-model="other_guests" type="text" class="form-control">
              </div>
            </div>
          </div>
          <!-- guest profile row -->
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="form-group">
                <label for="username" class="form-label">Username<span class="text-primary">*</span></label>
                <input id="username" v-model="username" type="text" class="form-control" required>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="form-group">
                <label for="birthday" class="form-label">Birthday Date></label>
                <input id="birthday" v-model="birthday" type="date" class="form-control">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="email" class="form-label">Email<span class="text-primary">*</span></label>
                <input id="email" v-model="email" type="email" class="form-control" required>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="marital-status" class="form-label">Marital Status</label>
                <select id="marital-status" v-model="marital_status" class="form-control" name="marital-status">
                  <option value="" disabled>Select Status</option>
                  <option value="single">Single</option>
                  <option value="engaged">Engaged</option>
                  <option value="married">Married</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="gender" class="form-label">Gender</label>
                <select id="gender" v-model="gender" class="form-control" name="gender">
                  <option value="" disabled>Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
          </div>
          <!-- guest profile row -->
          <div class="row">
            <div class="col-sm-6 col-md-2">
              <div class="form-group">
                <label for="country" class="form-label">Country</label>
                <select v-model="country" class="form-control" requied name="country">
                  <option value="" disabled>Select Country</option>
                  <option :value="`${c.name} (${c.countryCallingCodes[0]})`" v-for="(c,i) in countries" :key="i">{{c.name}} ({{c.countryCallingCodes[0]}})</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-group">
                <label for="phone" class="form-label">Phone Contact</label>
                <input id="phone" v-model="phone" type="number" class="form-control">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="full_address" class="form-label">Full Address</label>
                <input id="full_address" v-model="full_address" type="text" class="form-control">
              </div>
            </div>
          </div>
          <!-- guest profile row -->
          <div class="text-center mt-4">
            <button class="btn btn-dark mr-2" @click="cancelProfileUpdate">
              <span>&#x2715; Cancel</span>
            </button>
            <button type="submit" class="btn btn-outline-dark">
              <i class="far fa-save mr-2"></i>Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'GuestProfile',
  data: () => ({
    change_profile_img: false,
    edit_profile: false,
    profile_img_url: '',
    profile_img_to_upload: null,
    fullname: '',
    other_guests: '',
    email: '',
    username: '',
    birthday: '',
    marital_status: '',
    gender: '',
    country: '',
    phone: null,
    full_address: '',
    message: {
      error: '',
      success: '',
    },
  }),
  computed: {
    ...mapGetters(['user', 'countries', 'surl']),
    countryCode() {
      // return this.country.split('(')[1].split(')')[0];
      const ph = `${this.country.split('(')[1]}0`;
      const p = ph.split(')')[0];
      return p;
    },
    phoneNo() {
      return this.countryCode + this.phone;
    },
    ourCountry() {
      return this.country.split(' (')[0];
    },
  },
  methods: {
    onImgSelected(e) {
      /* eslint-disable prefer-destructuring */
      this.profile_img_to_upload = e.target.files[0];
    },
    uploadProfileImg() {
      const token = localStorage.getItem('_ikn');
      const id = localStorage.getItem('_ud');
      const fb = new FormData();
      fb.append('profileimg', this.profile_img_to_upload);
      axios.put(`${this.surl}/api/users/guests/${id}/update/personal-details`, fb, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          // this.profile_img_url = `http://localhost:3000/images/${response.data.file_meta_data.filename}`;
          const account = 'Guest';
          this.$store.dispatch('setCurrentUser', { account });
          this.edit_profile = false;
          this.message.success = response.data.message;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    async getGuestProfile() {
      if (this.user) {
        this.fullname = this.user.full_name ?? '';
        this.other_guests = this.user.other_guests ?? '';
        this.email = this.user.email ?? '';
        this.username = this.user.username ?? '';
        if (this.user.birthday) this.birthday = this.user.birthday.split('T')[0] ?? '';
        this.marital_status = this.user.marital_status ?? '';
        this.gender = this.user.gender ?? '';
        this.country = `${this.user.address.country}(+${this.user.address.zip})` ?? '';
        this.phone = this.user.contact.mobilephone.toString().split(`${this.user.address.zip}`)[1] ?? '';
        this.full_address = this.user.address.full ?? '';
        // this.profile_img_url = `http://localhost:3000/images/${this.user.profile_img_url}` ?? '';
      }
    },
    editProfile() {
      this.getGuestProfile();
      this.edit_profile = true;
      this.$store.dispatch('smoothScroll');
      // window.scroll({
      //   top: 100,
      //   left: 0,
      //   behavior: 'smooth',
      // });
    },
    updateGuestProfile(e) {
      e.preventDefault();
      const id = localStorage.getItem('_ud');
      const token = localStorage.getItem('_ikn');
      axios.put(`${this.surl}/api/users/guests/${id}/update/personal-details`, {
        fullname: this.fullname,
        email: this.email,
        username: this.username,
        birthday: this.birthday,
        marital_status: this.marital_status,
        gender: this.gender,
        country: this.ourCountry,
        phone: this.phoneNo,
        zip: this.countryCode,
        full_address: this.full_address,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const account = 'Guest';
          this.$store.dispatch('setCurrentUser', { account });
          // this.getGuestProfile();
          this.message.error = '';
          // console.log(response.data);
          this.edit_profile = false;
          this.message.success = response.data.message;
          this.$router.push('/guest/profile');
        })
        .catch((err) => {
          // console.log(err.response);
          this.message.error = err.response.data.message;
        });
    },
    cancelProfileUpdate() {
      this.edit_profile = false;
      this.change_profile_img = false;
      this.$store.dispatch('smoothScroll');
      // window.scroll({
      //   top: 100,
      //   left: 0,
      //   behavior: 'smooth',
      // });
    },
  },
  updated() {
    setTimeout(() => {
      this.message.success = '';
      this.message.error = '';
    }, 5000);
  },
  created() {
    this.getGuestProfile();
  },
};
</script>
<style scoped>
.svg-icon {
  stroke-width: 4.5 !important;
}
.profile-img {
    background: #fff;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
