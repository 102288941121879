<template>
  <!-- eslint-disable max-len -->
  <div class="container">
    <!-- The Modal -->
    <div class="modal" id="myModal">
      <div class="modal-dialog">
        <div class="modal-content p-3 mt-5">
          <!-- Modal Header -->
          <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close">
            <svg class="svg-icon w-100 h-100 svg-icon-light align-middle"><use xlink:href="#close-1"></use></svg>
          </button>
          <div class="modal-header" style="padding: 0;">
            <div class="container-fluid row nav nav tabs nav-pills ml-3 mr-1 mt-4" role="tablist" id="modalbar-1">
              <a class="col-6 nav-link active" style="font-size: 1.25rem; text-align: center;" data-toggle="tab" href="#modalbar-1-1"><strong data-toggle="tooltip" data-placement="bottom" title="" data-original-title="login">LOGIN</strong></a>
              <a class="col-6 nav-link" style="font-size: 1.25rem; text-align: center;" data-toggle="tab" href="#modalbar-1-2"><strong data-toggle="tooltip" data-placement="bottom" title="" data-original-title="signup">REGISTER</strong></a>
            </div>
          </div>
          <!-- Modal body -->
          <div class="modal-body tab-content">
            <div class="tab-pane fade active show" id="modalbar-1-1">
              <form @submit.prevent="login">
                <div class="form-group">
                  <!-- <label for="email" class="form-label">Email</label> -->
                  <input id="email-login" type="email" class="form-control" name="username" placeholder="Email">
                </div>
                <div class="form-group">
                  <!-- <label for="password" class="form-label">Password</label> -->
                  <div class="input-group">
                    <input id="password-login" type="password" class="form-control" name="password" placeholder="Password">
                    <!-- <div class="input-group-prepend"><span id="basic-addon1" class="input-group-text" style="color: #9CA3A9; background-color: #fff;"><i class="far fa-eye-slash "></i></span></div> -->
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col custom-control custom-checkbox">
                      <input id="custom-check-1" type="checkbox" class="custom-control-input">
                      <label for="custom-check-1" class="custom-control-label">Remember me</label>
                    </div>
                    <div class="col text-right" style="padding-right: 0px;">
                      <a href="#" class="text-info">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <button type="submit" class="btn btn-block btn-primary mt-3 p-3"><i class="fa fa-sign-in-alt mr-2"></i> Log in</button>
                  <hr>
                  <button type="submit" class="btn btn-block btn-info p-3" style="background-color: #4568B2; border-color: #4568B2"><i class="fab fa-facebook-f fa-lg"></i> Connect with Facebook</button>
                  <button type="submit" class="btn btn-block mt-3" style="border-color: #343a40;"><svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false" style="height: 15px; width: 15px; margin-bottom: 4px;"><g fill="none" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path d="M0 0h18v18H0V0z"></path></g></svg> <div style="display: inline-flex; align-self: center; margin-top: 6px;"> Connect with Google</div></button>
                </div>
              </form>
            </div>
            <div class="tab-pane fade" id="modalbar-1-2">
              <form @submit.prevent="register">
                <div class="form-group"  id="register-account">
                  <!-- <label for="name" class="form-label">Account Type</label> -->
                  <select id="account-register" class="form-control" name="account">
                    <option value="" class="text-muted">Select account type</option>
                    <option value="bb">Couple</option>
                    <option value="guest">Guest</option>
                    <option value="vendor">Vendor</option>
                  </select>
                </div>
                <div class="form-group">
                  <!-- <label for="name" class="form-label">Name</label> -->
                  <input id="name-register" type="text" class="form-control" name="username" placeholder="Username">
                </div>
                <div class="form-group">
                  <!-- <label for="email" class="form-label">Email</label> -->
                  <input id="email-register" type="email" class="form-control" name="email" placeholder="Email">
                </div>
                <div class="form-group">
                  <!-- <label for="password" class="form-label">Password</label> -->
                  <input id="password-register" type="password" class="form-control" name="password" placeholder="Password">
                </div>
                <div class="form-group text-center">
                  <button type="submit" class="btn btn-block btn-primary mt-3 p-3"> Register </button>
                  <hr>
                  <button type="submit" class="btn btn-block btn-info p-3" style="background-color: #4568B2; border-color: #4568B2"><i class="fab fa-facebook-f fa-lg"></i> Connect with Facebook</button>
                  <button type="submit" class="btn btn-block mt-3" style="border-color: #343a40;"><svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false" style="height: 15px; width: 15px; margin-bottom: 4px;"><g fill="none" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path d="M0 0h18v18H0V0z"></path></g></svg> <div style="display: inline-flex; align-self: center; margin-top: 6px;"> Connect with Google</div></button>
                </div>
              </form>
            </div>
          </div>
          <!-- Modal footer -->
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'SignModal',
  data: () => ({
  }),
  methods: {
    login() {
      this.$router.push({ name: 'bride-n-groom' });
      // window.location = '/bride-n-groom-account';
    },
    register() {
      this.$router.push({ name: 'login' });
      // window.location = '/login';
    },
  },
};
</script>
