<!-- eslint-disable max-len -->

<template>
  <div class="pt-5">
    <section class="hero">
      <div class="container">
        <!-- Breadcrumbs -->
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item active">404</li>
        </ol>
        <!-- Hero Content-->
        <div class="hero-content pb-5 text-center">
          <h1 class="mb-5">404 Oops!!</h1>
          <div class="row">
            <div class="col-xl-8 offset-xl-2"><p class="lead mb-0">Something went wrong!!</p></div>
          </div>
          <div class="mt-2">Back to <a href="/">Home Page</a></div>
        </div>
      </div>
    </section>
  </div>
</template>
