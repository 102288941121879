<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="block-body" v-if="editBudget">
      <form @submit.prevent="updateCoupleProfile">
        <!-- /.row-->
        <div class="row">
          <div class="col-sm-6 col-md-2">
            <div class="form-group">
              <label for="country" class="form-label">Category</label>
              <select id="country" v-model="country" class="form-control" required>
                <option value="" selected disabled>Select category</option>
                <option :value="c.category" v-for="(c,i) in Categories" :key="i">{{c.category}}</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="wedding_date" class="form-label">Wedding Date</label>
              <input id="wedding_date" v-model="wedding_date" type="date" class="form-control" required>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label for="country" class="form-label"><span class="d-none d-lg-inline-block">Location /</span> Currency</label>
              <select class="form-control" v-model="currency">
                <option disabled value="">Select currency</option>
                <option v-for="(c,i) in Currencies" :key="i"  :value="`${c.country} - ${c.currency}`">{{c.country}} - {{c.currency}}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="form-group">
              <label for="vendor_name" class="form-label">Vendor <span class="d-none d-lg-inline-block">Name</span> <small class="text-lowercase">(optional)</small></label>
              <div class="input-group">
                <input id="vendor_name" v-model="vendor_name" type="text" class="form-control">
                <button type="submit" class="btn btn-outline-dark ml-4 d-none d-md-block">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row-->
        <div class="text-center my-4 d-sm-block d-md-none">
          <button type="submit" class="btn btn-outline-dark">
            <i class="fas fa-search mr-2"></i>Find
          </button>
        </div>
        <!-- /.row-->
        <div class="text-center my-5">
          <button class="btn btn-dark mr-0 mr-md-2" @click="cancelBudgetEdit">
            <span>&#x2715; Cancel</span>
          </button>
          <!-- <br class="d-sm-block d-md-none">
          <br class="d-sm-block d-md-none">
          <button type="submit" class="btn btn-outline-dark">
            <i class="fas fa-plus mr-2"></i>Add to Budget
          </button> -->
        </div>
      </form>
    </div>
    <div class="card mb-5 shadow-sm" v-if="!editBudget">
      <table class="table table-borderless table-hover table-responsive-md">
        <thead class="bg-light">
          <tr class="card-header">
            <th class="text-uppercase text-sm">Item</th>
            <th class="text-uppercase text-sm text-right">Price</th>
            <th class="text-uppercase text-sm text-right">Amount Paid</th>
            <th class="text-uppercase text-sm text-center">Status</th>
            <th class="text-uppercase text-sm">Vendor</th>
            <th class="text-uppercase text-sm" v-if="role.isC"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(b, i) in Budget" :key="i">
            <td class="align-middle">{{i+1}}. {{b.item}}</td>
            <td class="align-middle text-right">{{b.price === b.amount_paid && !role.isC ? 'Covered' : '$'+b.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td>
            <td class="align-middle text-right">{{b.price === b.amount_paid && !role.isC ? 'Covered' : '$'+b.amount_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</td>
            <td class="align-middle text-center">
              <span class="badge text-uppercase p-1" :class="b.amount_paid/b.price>0 ? (b.amount_paid/b.price>0.5 ? (b.amount_paid/b.price === 1 ? 'badge-success' : 'badge-info') : 'badge-warning'): 'badge-danger'">
                <span v-if="b.price-b.amount_paid > 0">{{(b.amount_paid*100/b.price).toFixed(1)}}% Paid</span>
                <span v-if="b.price-b.amount_paid === 0">Covered</span>
              </span>
            </td>
            <td class="align-middle">{{b.vendor}}</td>
            <td class="align-middle" v-if="role.isC"><a :href="b.vendor_url" class="btn btn-outline-dark btn-sm shadow-sm" data-toggle="modal" :data-target="'#BudgetModal'+i">View</a></td>
          </tr>
        </tbody>
        <tbody v-if=" Budget.length === 0">
          <td class="align-middle text-muted">No items yet</td>
        </tbody>
        <tfoot v-if="role.isC">
          <tr v-if=" Budget.length !== 0">
            <td class="align-middle text-uppercase text-sm"><strong>Total</strong></td>
            <td class="align-middle text-right"><strong>${{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</strong></td>
            <td class="align-middle text-right"><strong>${{paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</strong></td>
            <td class="align-middle text-center"><span class="badge text-uppercase p-1" :class="status>0 ? (status>0.5 ? (status === 1 ? 'badge-success' : 'badge-info') : 'badge-warning'): 'badge-danger'"><strong>{{status !== null ? status*100+'% Paid':'No items'}}</strong></span></td>
            <td class="align-middle"></td>
            <td class="align-middle"></td>
          </tr>
        </tfoot>
      </table>
      <button class="btn btn-outline-light btn-sm text-lg text-dark" @click="addToBudget">+</button>
    </div>
    <!-- Quickview Modal -->
    <div v-for="(b, i) in Budget" :key="i" :id="'BudgetModal'+i" tabindex="-1" role="dialog" aria-hidden="true" class="modal fade quickview">
      <div role="document" class="modal-dialog modal-lg">
        <div class="modal-content">
          <button type="button" data-dismiss="modal" aria-label="Close" class="close modal-close">
            <svg class="svg-icon w-100 h-100 svg-icon-light align-middle">
              <use xlink:href="#close-1"> </use>
            </svg>
          </button>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6">
                <div data-slider-id="1" class="owl-carousel owl-theme owl-dots-modern detail-full">
                  <div style="background: center center url('images/venues.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues1.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues2.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues3.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                  <div style="background: center center url('images/venues4.jpg') no-repeat; background-size: cover;" class="detail-full-item-modal">  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div>
                  <h2 class="mb-4 mt-4 mt-lg-1">{{b.vendor}}</h2>
                  <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
                    <ul class="list-inline mb-2 mb-sm-0">
                      <li class="list-inline-item h4 font-weight-light mb-0">${{b.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</li>
                      <li class="list-inline-item text-muted font-weight-light">
                        <del>{{(b.price*1.2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</del>
                      </li>
                    </ul>
                    <div class="d-flex align-items-center">
                      <ul class="list-inline mr-2 mb-0">
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-primary"></i></li>
                        <li class="list-inline-item mr-0"><i class="fa fa-star text-gray-300"></i></li>
                      </ul><span class="text-muted text-uppercase text-sm">25 reviews</span>
                    </div>
                  </div>
                  <h6 class="mb-4 detail-option-heading">Amount Paid: <span>${{b.amount_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</span></h6>
                  <p class="mb-4 text-muted">{{b.item}}</p>
                  <form action="/book" method="post" v-if="b.price-b.amount_paid > 0">
                    <div class="row">
                      <div class="col-sm-6 col-lg-12 detail-option mb-3">
                        <h6 class="detail-option-heading">Guests <span>(required)</span></h6>
                        <input name="items" type="number" value="50" class="form-control detail-quantity">
                        <!-- <label for="size_0" class="btn btn-sm btn-outline-secondary detail-option-btn-label">
                          300
                          <input type="radio" name="size" value="value_0" id="size_0" required class="input-invisible">
                        </label>
                        <label for="size_1" class="btn btn-sm btn-outline-secondary detail-option-btn-label">
                          1000
                          <input type="radio" name="size" value="value_1" id="size_1" required class="input-invisible">
                        </label>
                        <label for="size_2" class="btn btn-sm btn-outline-secondary detail-option-btn-label">
                          2500
                          <input type="radio" name="size" value="value_2" id="size_2" required class="input-invisible">
                        </label> -->
                      </div>
                      <div class="col-sm-6 col-lg-12 detail-option mb-3">
                        <h6 class="detail-option-heading">Venue Type <span>(required)</span></h6>
                        <label for="material_0" class="btn btn-sm btn-outline-secondary detail-option-btn-label">
                          Indoor
                          <input type="radio" name="material" value="value_0" id="material_0" required class="input-invisible">
                        </label>
                        <label for="material_1" class="btn btn-sm btn-outline-secondary detail-option-btn-label">
                          Outdoor
                          <input type="radio" name="material" value="value_1" id="material_1" required class="input-invisible">
                        </label>
                      </div>
                      <div class="col-12 col-lg-6 detail-option mb-4">
                        <label class="detail-option-heading font-weight-bold">Pay Remainder<span>$(required)</span></label>
                        <input name="items" type="number" :value="(b.price-b.amount_paid)" class="form-control detail-quantity">
                      </div>
                    </div>
                    <ul class="list-inline" v-if="b.price-b.amount_paid > 0">
                      <li class="list-inline-item">
                        <button type="submit" class="btn btn-dark btn-lg mb-1"> <i class="fa fa-shopping-cart mr-2"></i>Book</button>
                      </li>
                      <li class="list-inline-item"><a href="#" class="btn btn-outline-secondary mb-1">Replace</a></li>
                    </ul>
                  </form>
                  <ul class="list-inline" v-if="b.price-b.amount_paid === 0">
                    <li class="list-inline-item badge text-uppercase p-2 badge-success">Paid in full</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BudgetComponent',
  props: {
  },
  data() {
    return {
      editBudget: true,
      currency: '',
      country: '',
      vendor_name: '',
      wedding_date: '',
    };
  },
  computed: {
    ...mapState(['Budget', 'role', 'Vendors', 'Categories', 'Countries', 'user', 'Currency']),
    total() {
      let t = 0;
      this.Budget.forEach((i) => {
        t += Math.abs(i.price);
      });
      return t;
    },
    paid() {
      let p = 0;
      this.Budget.forEach((i) => {
        p += Math.abs(i.amount_paid);
      });
      return p;
    },
    status() {
      const s = Math.abs(this.paid / this.total).toFixed(3);
      return s >= 0 || s <= 1 ? s : null;
    },
    Currencies() {
      const cs = [];
      this.Countries.forEach((c) => {
        if (c.currencies[0]) cs.push({ country: c.name, currency: c.currencies[0] });
      });
      return cs.sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = a.country.toUpperCase();
        const nameB = b.country.toUpperCase();
        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });
    },
  },
  methods: {
    getDefaultSearchAttributes() {
      /* eslint-disable prefer-destructuring */
      this.currency = this.$store.getters.Currency;
      this.wedding_date = this.$store.getters.wedding_date.split('T')[0];
    },
    addToBudget() {
      this.editBudget = true;
      this.$store.dispatch('smoothScroll');
      // window.scrollTo(0, 0);
      // window.scroll({
      //   top: 100,
      //   left: 0,
      //   behavior: 'smooth',
      // });
    },
    cancelBudgetEdit() {
      this.editBudget = false;
      this.$store.dispatch('smoothScroll');
      // window.scrollTo(0, 0);
      // window.scroll({
      //   top: 100,
      //   left: 0,
      //   behavior: 'smooth',
      // });
    },
  },
  created() {
    this.getDefaultSearchAttributes();
  },
  mounted() {
    // this.getDefaultSearchAttributes();
  },
  updated() {
    this.getDefaultSearchAttributes();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
