<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-4">
      <h3 class="hero-heading">Offerings, Services & Products</h3>
      <transition name="fade">
        <p v-if="message.error" class="px-3 py-2 alert alert-danger mb-3">{{message.error}}</p>
      </transition>
      <transition name="fade">
        <p v-if="message.success" class="px-3 py-2 alert alert-success mb-3">{{message.success}}</p>
      </transition>
    </div>
    <!-- <div class="text-lg">
      <button class="btn btn-outline-dark" type="button" name="button">Add Offering</button>
    </div> -->
    <div class="mt-5">
      <!-- Button trigger modal-->
      <div class="d-flex justify-content-between align-items-center">
        <button type="button" @click="showOfferingModel=true" class="btn btn-outline-dark d-flex justify-content-between align-items-center">
          <!-- <svg width="16" height="16" fill="currentColor" class="bi bi-plus-lg"><path d="M8 0a1 1 0 011 1v6h6a1 1 0 110 2H9v6a1 1 0 11-2 0V9H1a1 1 0 010-2h6V1a1 1 0 011-1z"/></svg> -->
          <svg width="16" height="16" fill="currentColor"><path d="M8 4a.5.5 0 01.5.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3A.5.5 0 018 4z"/></svg>
          <span class="ml-2">Add Offering</span>
        </button>
        <div class="border-secondary d-flex align-items-center">
          <button type="button" class="btn" :class="layout_list?'bg-muted':'bg-light'" @click="changeLayout('list')">
            <svg width="16" height="16" fill="currentColor" class="text-secondary"><path d="M2.5 12a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"/></svg>
          </button>
          <button type="button" class="btn" :class="layout_list?'bg-light':'bg-muted'" @click="changeLayout('grid')">
            <svg width="16" height="16" fill="currentColor" class="text-secondary"><path d="M1 2.5A1.5 1.5 0 012.5 1h3A1.5 1.5 0 017 2.5v3A1.5 1.5 0 015.5 7h-3A1.5 1.5 0 011 5.5v-3zM2.5 2a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm6.5.5A1.5 1.5 0 0110.5 1h3A1.5 1.5 0 0115 2.5v3A1.5 1.5 0 0113.5 7h-3A1.5 1.5 0 019 5.5v-3zm1.5-.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zM1 10.5A1.5 1.5 0 012.5 9h3A1.5 1.5 0 017 10.5v3A1.5 1.5 0 015.5 15h-3A1.5 1.5 0 011 13.5v-3zm1.5-.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zm6.5.5A1.5 1.5 0 0110.5 9h3a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5h-3A1.5 1.5 0 019 13.5v-3zm1.5-.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3z"/></svg>
          </button>
        </div>
      </div>
      <!-- Modal-->
      <div class="modal fade" :class="showOfferingModel ? 'show':''" :style="showOfferingModel ? 'display: block;':'display: none;'">
        <div class="w-100 h-100 bg-dark position-fixed" style="opacity: 0.33; z-index: 1051; bottom: 0; right: 0;" :style="showOfferingModel ? 'display: block;':'display: none;'" @click="closeOfferingForm"></div>
        <div class="modal-dialog" style="position: fixed; z-index: 1055; left: 0; right: 0;">
          <div class="modal-content">
            <button type="button" @click="closeOfferingForm" class="close modal-close">
              <svg viewBox="0 0 64 64" class="svg-icon w-100 h-100 svg-icon-light align-middle"><path fill="none" stroke-miterlimit="10" d="M41.999 20.002l-22 22m22 0L20 20" stroke-linejoin="round" stroke-linecap="round"/></svg>
            </button>
            <form class="px-3">
              <div class="py-1 px-3">
                <h2 class="modal-title mb-4 text-center"><span v-if="!edit_offering">New</span> Offering</h2>
                <!-- <p>{{name}} | {{category}} | {{description}} | {{price}} | {{country}}</p> -->
                <div class="form-group">
                  <label for="name" class="form-label">Name</label>
                  <input type="text" class="form-control" name="name" v-model="name">
                </div>
                <div class="form-group">
                  <label for="category" class="form-label">Category</label>
                  <select name="category" v-model="category" class="form-control">
                    <option value="" disabled>Select category</option>
                    <option :value="c.category" v-for="(c,i) in Categories" :key="i">{{c.category}}</option>
                  </select>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="currency" class="form-label">Currency</label>
                      <select name="currency" v-model="country" class="form-control w-100">
                        <option value="" disabled>Select currency</option>
                        <option v-for="(c,i) in currencies" :key="i"  :value="`${c.country} - ${c.currency}`">{{c.country}} - {{c.currency}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="price" class="form-label">Price</label>
                      <input type="number" name="price" v-model="price" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="description" class="form-label">Description</label>
                  <textarea name="description" rows="2" class="form-control w-100 p-2" v-model="description" placeholder="Add short description of offering, service or product.."></textarea>
                </div>
                <div class="form-group">
                  <label for="offeringimg" class="form-label">{{edit_offering?'Change':'Add'}} Image</label>
                  <input type="file" ref="offeringimg" name="offeringimg" class="btn btn-light border-md mx-auto" @change="onImgSelected">
                </div>
              </div>
              <div class="modal-footer justify-content-center mb-2">
                <button type="button" @click="closeOfferingForm" class="btn btn-outline-dark">Close</button>
                <button type="submit" class="btn btn-success" @click="addOffering" v-if="!edit_offering">Save</button>
                <button type="submit" class="btn btn-success" @click="updateOffering" v-if="edit_offering">Save changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="my-4">
      <!-- offerings -->
      <div class="row" v-if="!layout_list">
        <div class="col-md-6 col-lg-4 col-xl-3" v-for="(o,i) in user.offerings" :key="i">
          <div class="mb-3 vendor-card-img shadow-sm">
            <img :src="`http://localhost:3000/images/${o.img_url}`" alt="offer image" class="card-img-top offering-card-img">
            <div class="card-body">
              <div class="card-text d-flex justify-content-between align-items-center mb-3">
                <span class="badge badge-primary text-white px-1 shadow-sm rounded-iwh">{{o.category}}</span>
                <p class="text-dark" style="margin-bottom: 0;">{{o.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} {{o.country.split('-')[1]}}</p>
              </div>
              <h6 class="card-title text-nowrap-ellipsis text-uppercase">{{o.name}}</h6>
              <p class="card-text text-sm mt-2 line-limit text-dark">{{o.description}}</p>
              <div class="w-100 d-flex justify-content-between">
                <button type="button" class="btn btn-light border-0 btn-sm d-flex justify-content-center align-items-center" @click="editOffering(o)">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-pencil-square text-secondary"><path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"/></svg>
                  <span class="ml-1 d-none">Edit</span>
                </button>
                <button type="button" class="btn btn-light border-sm-0 btn-sm d-flex justify-content-center align-items-center" @click="removeOffering(o._id)">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-trash text-secondary"><path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>
                  <span class="ml-1 d-none">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5" v-if="layout_list">
        <table class="table table-borderless table-hover table-responsive-md">
          <thead class="bg-light">
            <tr class="text-sm text-secondary">
              <!-- <th class="text-uppercase text-sm">Ref</th> -->
              <th class="text-uppercase text-sm">Name</th>
              <!-- <th class="text-uppercase text-sm">Category</th> -->
              <th class="text-uppercase text-sm">Price</th>
              <th class="text-uppercase text-sm">Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(o,i) in user.offerings" :key="i" class="shadow-s border-bottom border-light" @mouseover="showED=true" @mouseout="showED=false">
              <!-- <td class="align-middle">#00{{i+1}}</td> -->
              <td class="align-middle d-flex justify-content-start align-items-center">
                <img :src="`http://localhost:3000/images/${o.img_url}`" alt="offer image" class="img-fluid" style="height: 2.5rem; width: 2.5rem; border-radius: 0px;">
                <div class="ml-3"><span class="font-weight-bold text-uppercase text-dark">{{o.name}}</span><br><span class="text-muted text-sm">Category: {{o.category}}</span></div>
              </td>
              <!-- <td class="align-middle">{{o.category}}</td> -->
              <td class="align-middle">{{o.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} {{o.country.split('-')[1]}}</td>
              <td class="align-middle text-dark list-line-limit">{{o.description}}</td>
              <td class="align-middle">
                <div class="d-flex justify-content-end align-items-center">
                  <button type="button" class="btn btn-light btn-sm" @click="editOffering(o)">
                    <svg width="16" height="16" fill="currentColor" class="bi bi-pencil-square text-secondary"><path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"/></svg>
                  </button>
                  {{}}
                  <button type="button" class="btn btn-light btn-sm ml-3" @click="removeOffering(o._id)">
                    <svg width="16" height="16" fill="currentColor" class="bi bi-trash text-secondary"><path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'Offerings',
  props: {
  },
  data() {
    return {
      name: '',
      category: '',
      description: '',
      price: null,
      country: '',
      offering_id: null,
      // offering_img_url: '',
      upload_offering_img: null,
      // change_offering_img: false,
      edit_offering: false,
      showOfferingModel: false,
      message: {
        error: '',
        success: '',
      },
      showED: false,
      layout_list: false,
    };
  },
  computed: {
    ...mapState(['Offerings', 'Categories', 'Countries']),
    ...mapGetters(['user', 'countries', 'currencies', 'surl']),
  },
  methods: {
    onImgSelected(e) {
      /* eslint-disable prefer-destructuring */
      this.upload_offering_img = e.target.files[0];
    },
    clearOfferingForm() {
      this.name = '';
      this.category = '';
      this.description = '';
      this.price = '';
      this.country = '';
      this.upload_offering_img = null;
      // clear offering image input
      this.$refs.offeringimg.value = null;
    },
    setOfferingForm(o) {
      this.name = o.name;
      this.category = o.category;
      this.description = o.description;
      this.price = o.price;
      this.country = o.country;
    },
    closeOfferingForm() {
      this.showOfferingModel = false;
      this.edit_offering = false;
      this.clearOfferingForm();
    },
    addOffering(e) {
      e.preventDefault();
      const token = localStorage.getItem('_ikn');
      const id = localStorage.getItem('_ud');
      const fb = new FormData();
      fb.append('offeringimg', this.upload_offering_img);
      fb.append('name', this.name);
      fb.append('category', this.category);
      fb.append('description', this.description);
      fb.append('price', this.price);
      fb.append('country', this.country);
      axios.put(`${this.surl}/api/users/vendors/${id}/add/offering`, fb, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          const account = 'Vendor';
          this.$store.dispatch('setCurrentUser', { account });
          this.showOfferingModel = false;
          this.clearOfferingForm();
          // this.change_offering_img = false;
          this.message.success = response.data.message;
        })
        .catch((err) => {
          this.message.error = err.response.data.message;
        });
    },
    editOffering(o) {
      // console.log(id);
      // alert(id);
      this.edit_offering = true;
      this.showOfferingModel = true;
      /* eslint-disable no-underscore-dangle */
      this.offering_id = o._id;
      this.setOfferingForm(o);
    },
    updateOffering(e) {
      e.preventDefault();
      const token = localStorage.getItem('_ikn');
      const id = localStorage.getItem('_ud');
      const fb = new FormData();
      fb.append('newofferingimg', this.upload_offering_img);
      fb.append('name', this.name);
      fb.append('category', this.category);
      fb.append('description', this.description);
      fb.append('price', this.price);
      fb.append('country', this.country);
      fb.append('offering_id', this.offering_id);
      // fb.append('offering_id', 1);
      axios.put(`${this.surl}/api/users/vendors/${id}/update/offering`, fb, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          const account = 'Vendor';
          this.$store.dispatch('setCurrentUser', { account });
          this.showOfferingModel = false;
          this.clearOfferingForm();
          this.edit_offering = false;
          // this.change_offering_img = false;
          this.message.success = response.data.message;
          this.$store.dispatch('smoothScroll');
        })
        .catch((err) => {
          this.message.error = err.response.data.message;
        });
    },
    removeOffering(oId) {
      const token = localStorage.getItem('_ikn');
      const id = localStorage.getItem('_ud');
      axios.delete(`${this.surl}/api/users/vendors/${id}/remove/offering`, {
        data: { oId },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          const account = 'Vendor';
          this.$store.dispatch('setCurrentUser', { account });
          this.message.success = response.data.message;
          this.$store.dispatch('smoothScroll');
        })
        .catch((err) => {
          console.log(err.response);
          // this.message.error = err.response.data.message;
        });
    },
    changeLayout(view) {
      if (view === 'list') this.layout_list = true;
      if (view === 'grid') this.layout_list = false;
    },
    showEDBtn(e) {
      console.log(e);
    },
  },
  updated() {
    setTimeout(() => {
      this.message.success = '';
      this.message.error = '';
    }, 5000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.model {
  z-index: 1055 !important;
}
.img-fluid {
  object-fit: cover;
}
.line-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.list-line-limit {
  /* display:inline-block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: auto; */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bg-muted {
  background-color: #e9ecef;
}
.text-secondary {
  color: #464a4e !important;
}
/* tr, td {
  margin-top: 0 !important;
  padding-top: 0.25rem !important;
  margin-bottom: 0 !important;
  padding-bottom: 0.25rem !important;
} */
</style>
