<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content mb-4">
      <h3 class="hero-heading text-center">Gifts Given</h3>
      <p>Surprise couples with gifts, cash or fully funded honey experiences. Check out their registry for clues!</p>
    </div>
    <gifts-given-component />
  </div>
</template>

<script>
import GiftsGivenComponent from '../GiftsGivenComponent.vue';

export default {
  name: 'GiftsGiven',
  components: { GiftsGivenComponent },
  props: {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
