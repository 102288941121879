<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-4">
      <h3 class="hero-heading">Contributions Given</h3>
    </div>
    <ContributionsGivenComponent />
  </div>
</template>

<script>
/* eslint-disable max-len */
import ContributionsGivenComponent from '../ContributionsGivenComponent.vue';

export default {
  name: 'ContributionsGiven',
  components: { ContributionsGivenComponent },
  props: {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
