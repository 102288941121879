<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-4">
      <h3 class="hero-heading">My Invites</h3>
    </div>
    <invites-component />
  </div>
</template>

<script>
import InvitesComponent from '../InvitesComponent.vue';

export default {
  name: 'GuestList',
  components: { InvitesComponent },
  props: {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
