import { createApp } from 'vue';
// import axios from 'axios';
import Maska from 'maska';
// import firebase from 'firebase/app';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// const firebaseConfig = {
//   apiKey: 'AIzaSyBAKtQqJCPWrONTsDERvBhIhUp5CADM0kk',
//   authDomain: 'iwh-vue.firebaseapp.com',
//   databaseURL: 'https://iwh-vue.firebaseio.com',
//   projectId: 'iwh-vue',
//   storageBucket: 'iwh-vue.appspot.com',
//   messagingSenderId: '335008150299',
//   appId: '1:335008150299:web:a11481e15337c1d54ed9be',
//   measurementId: 'G-W0LVYQWH06',
// };

// firebase.initializeApp(firebaseConfig);

createApp(App).use(store).use(router).use(Maska)
  .mount('#app');
