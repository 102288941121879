<!-- eslint-disable max-len -->

<template>
<div class="products-grid order-lg-2 px-5 mt-6">
  <div class="hero-content text-center mb-3 pt-5">
    <h3 class="hero-heading">Vendor Categories</h3>
  </div>
  <header class="product-grid-header">
    <div class="mr-3 mb-3">
      Showing <strong>1-12 </strong>of <strong>{{Categories.length}}</strong> Categories
    </div>
    <div class="mr-3 mb-3"><span class="mr-2">Show</span><a href="#" class="product-grid-header-show active">12 </a><a href="#" class="product-grid-header-show ">24 </a><a href="#" class="product-grid-header-show ">All </a>
    </div>
    <div class="mb-3 d-flex align-items-center"><span class="d-inline-block mr-1">Sort by</span>
      <select class="custom-select w-auto border-0">
        <option value="orderby_0">Default</option>
        <option value="orderby_1">Popularity</option>
        <option value="orderby_2">Rating</option>
        <option value="orderby_3">Newest first</option>
      </select>
    </div>
  </header>
  <div class="row">
    <!-- category-->
    <div class="col-xl-2 col-sm-6" v-for="(c,i) in Categories" :key="i">
      <div class="product">
        <div class="product-image">
          <img :src="c.category_img_url" :alt="c.category" class="img-fluid" />
          <div class="product-hover-overlay">
            <a href="#" class="product-hover-overlay-link"></a>
            <div class="product-hover-overlay-buttons">
              <a href="#" class="btn btn-dark btn-buy"><i class="fa-search fa"></i><span class="btn-buy-label ml-2">View</span></a>
            </div>
          </div>
        </div>
        <div class="py-2">
          <p class="text-muted text-sm mb-1">{{c.services}}</p>
          <h3 class="h6 text-uppercase mb-1"><a href="#" class="text-dark">{{c.category}}</a></h3><span class="text-muted">${{c.minprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}} - ${{c.maxprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</span>
        </div>
      </div>
    </div>
    <!-- /category-->
  </div>
  <nav aria-label="page navigation" class="d-flex justify-content-center mb-5 mt-3">
    <ul class="pagination">
      <li class="page-item"><a href="#" aria-label="Previous" class="page-link"><span aria-hidden="true">Prev</span><span class="sr-only">Previous</span></a></li>
      <li class="page-item active"><a href="#" class="page-link">1 </a></li>
      <li class="page-item"><a href="#" class="page-link">2 </a></li>
      <li class="page-item"><a href="#" aria-label="Next" class="page-link"><span aria-hidden="true">Next</span><span class="sr-only">Next </span></a></li>
    </ul>
  </nav>
</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'VendorCategories',
  data: () => ({
  }),
  computed: {
    ...mapState(['Categories']),
  },
};
</script>
<style scoped>
</style>
