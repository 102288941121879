<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-3">
      <h3 class="hero-heading">Vendor Bookings</h3>
    </div>
    <p class="text-md mb-4 pb-1">Select vendors for wedding and add them to your budget items to start booking them. </p>
    <BudgetComponent />
  </div>
</template>

<script>
import BudgetComponent from '../BudgetComponent.vue';

export default {
  name: 'BookVendors',
  components: { BudgetComponent },
  props: {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
