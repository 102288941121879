<!-- eslint-disable max-len -->
<template>
  <div>
    <section v-if="loading">
      <div style="margin-top: 8rem"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 about-text-column order-lg-2">
            <div class="about-text d-flex justify-content-center align-items-center">
              <div class="spinner-grow text-secondary spinner-grow-sm mx-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary spinner-grow-sm mx-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary spinner-grow-sm mx-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="couple.id&&!loading">
      <div style="margin-top: 8rem"></div>
      <div class="container-fluid">
        <div class="row d-flex justify-content-center align-items-center">
          <div v-if="couple.launch.card_url" class="col-lg-6 p-lg-0 about-image-column order-lg-1 dark-overlay couple-page-img" :style="`background-image: url(http://localhost:3000/images/${couple.launch.card_url})`"></div>
          <!-- <div v-if="!couple.launch.card_url" class="col-lg-6 p-lg-0 about-image-column order-lg-1 dark-overlay couple-page-img" :style="`background-image: url(${profile_img_url})`"></div> -->
          <div class="about-text-column order-lg-2 bg-gray-100" :class="couple.launch.card_url?'col-lg-6':'col-lg-6 shadow-sm'">
            <div class="about-text text-center">
              <!-- {{couple.id}} -->
              <h2 v-if="couple.wedding_date" class="mb-4">Save the Date</h2>
              <h6 v-if="couple.wedding_date" class="text-uppercase text-muted letter-spacing-5 mb-5">{{couple.wedding_date.split('T')[0]}}</h6>
              <!-- <h1 class="mb-3 text-serif">{{coupleNames}}</h1> -->
              <h1 v-if="coupleNames" class="mb-3 text-serif">{{couple.groom.full_name}}<br>&<br>{{couple.bride.full_name}}</h1>
              <p v-if="couple.username" class="text-sm text-muted mb-4">@{{couple.username}}</p>
              <p v-if="couple.launch.story" class="text-lg text-secondary">{{couple.launch.story}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="couple.id&&!loading" class="py-6">
      <div class="container">
        <div class="row block-body">
          <div class="col-lg-12 text-center">
            <h2 class="mb-5"> Contributions</h2>
            <div v-if="!supportUs">
              <button v-if="couple.id !== user._id" type="button" class="mb-5 btn btn-outline-dark" @click="supportUs=true">Support Us</button>
              <router-link v-if="couple.id === user._id" type="button" class="mb-5 btn btn-outline-dark" :to="{ name: 'couple-contributions' }">View Contributions</router-link>
              <p class="text-lg text-secondary">Thank you for walking with us, supporting us and contributing to our journey!</p>
            </div>
            <div v-if="supportUs&&couple.id" class="row d-flex justify-content-center">
              <div class="col-lg-7 mb-5 mb-md-0">
                <form @submit.prevent="supportCouple" class="form">
                  <div class="controls">
                    <div class="row text-left">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="fullname" class="form-label">Fullname<span class="text-primary">*</span></label>
                          <input type="text" v-model="contribution.fullname" placeholder="e.g. First Middle Last" required class="form-control">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="email" class="form-label">Your email<span class="text-primary">*</span></label>
                          <input type="email" v-model="contribution.email" placeholder="e.g. name@email.com" required="required" class="form-control">
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-2">
                        <div class="form-group">
                          <label for="currency" class="form-label">Currency<span class="text-primary">*</span></label>
                          <select name="currency" v-model="contribution.currency" class="form-control w-100">
                            <option value="" disabled>Select currency</option>
                            <option v-for="(c,i) in currencies" :key="i"  :value="`${c.country} - ${c.currency}`">{{c.country}} - {{c.currency}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6  col-md-4">
                        <div class="form-group">
                          <label for="ammount" class="form-label">Amount<span class="text-primary">*</span></label>
                          <input type="number" v-model="contribution.amount" placeholder="Enter amount.." required class="form-control">
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-2">
                        <div class="form-group">
                          <label for="country" class="form-label">Country<span class="text-primary">*</span></label>
                          <select v-model="contribution.country" class="form-control" requied name="country">
                            <option value="" disabled>Select Country</option>
                            <option :value="`${c.name} (${c.countryCallingCodes[0]})`" v-for="(c,i) in countries" :key="i">{{c.name}} ({{c.countryCallingCodes[0]}})</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4">
                        <div class="form-group">
                          <label for="phone" class="form-label">Phone contact<span class="text-primary">*</span></label>
                          <input type="text" v-model="contribution.phone" placeholder="Add phone contact" required class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="form-group text-left">
                      <label for="message" class="form-label">Message for <router-link :to="`/couples/${couple.username}`" class="text-muted" data-toggle="tooltip" data-placement="top" title="" :data-original-title="`@${couple.username}`">{{coupleNames}} <span class="text-info text-lowercase">(@{{couple.username}})</span></router-link></label>
                      <textarea rows="4" v-model="contribution.message" placeholder="Add special message or wishes..." class="form-control"></textarea>
                    </div>
                    <button type="submit" class="btn btn-outline-dark mt-4">Send Contribution</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!couple.id&&!loading" class="py-6">
      <div style="margin-top: 8rem"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <!-- <h6 class="text-uppercase text-muted letter-spacing-5 mb-4">Support</h6> -->
            <h2 class="mb-5">Couple Not Found!!</h2>
            <div>
              <p class="text-md text-muted">Use valid couple link or return to <a href="/">home page</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'couple-page',
  data() {
    return {
      supportUs: false,
      couple: {
        id: null,
        username: '',
        bride: {
          full_name: '',
          birthday: null,
          father: '',
          mother: '',
        },
        groom: {
          full_name: '',
          birthday: null,
          father: '',
          mother: '',
        },
        wedding_date: null,
        launch: {
          card_url: '',
          story: '',
          date: null,
        },
      },
      acc: '',
      contribution: {
        userId: null,
        username: '',
        account: '',
        fullname: '',
        amount: null,
        currency: '',
        coupleId: null,
        email: '',
        phone: '',
        country: '',
        message: '',
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'countries', 'currencies', 'account', 'profile_img_url', 'surl']),
    coupleNames() {
      if (this.couple.groom.full_name && this.couple.bride.full_name) return `${this.couple.groom.full_name} & ${this.couple.bride.full_name}`;
      return '';
    },
    countryCode() {
      const ph = `${this.contribution.country.split('(')[1]}0`;
      const p = ph.split(')')[0];
      return p;
    },
  },
  methods: {
    clearSupportForm() {
      this.contribution = {
        userId: null,
        username: '',
        fullname: '',
        amount: null,
        currency: '',
        coupleId: null,
        email: '',
        phone: '',
        country: '',
        message: '',
      };
    },
    setSupportForm() {
      this.acc = localStorage.getItem('_acc');
      const id = localStorage.getItem('_ud');
      if (id) {
        this.contribution = {
          userId: this.user._id,
          username: this.user.username,
          fullname: this.user.full_name,
          amount: null,
          currency: '',
          coupleId: this.couple.id,
          email: this.user.email,
          phone: this.user.contact.mobilephone,
          country: `${this.user.address.country} (+${this.user.address.zip})`,
          message: '',
        };
      }
    },
    supportCouple(e) {
      e.preventDefault();
      // console.log({ contribion: this.contribution });
      const {
        fullname, amount, currency, email, phone, country, message,
      } = this.contribution;
      const userId = this.user ? this.user._id : null;
      const username = this.user ? this.user.username : '';
      const zip = this.countryCode;
      const account = this.acc;
      const coupleFullName = this.coupleNames;
      console.log({
        userId, username, account, fullname, amount, currency, email, phone, country, zip, message, coupleFullName,
      });
      axios.put(`http://localhost:3000/api/users/couples/public/${this.couple.id}/send/contribution`, {
        userId, username, account, fullname, amount, currency, email, phone, country, zip, message, coupleFullName,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response.data);
          this.supportUs = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getCouple() {
      // console.log(this.$route.params.username);
      axios.get(`${this.surl}/api/users/couples/public/${this.$route.params.username}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          // console.log(response.data);
          this.couple = response.data.couple;
          // console.log({ user: this.user });
          // set support form with user data
          this.setSupportForm();
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.response);
          if (err) {
            this.clearCoupleData();
            this.loading = false;
          }
        });
    },
    clearCoupleData() {
      this.couple = {
        id: null,
        username: '',
        bride: {
          full_name: '',
          birthday: null,
          father: '',
          mother: '',
        },
        groom: {
          full_name: '',
          birthday: null,
          father: '',
          mother: '',
        },
        wedding_date: null,
        launch: {
          card_url: '',
          story: '',
          date: null,
        },
      };
    },
  },
  created() {
    this.getCouple();
    // this.setSupportForm();
  },
  updated() {
    this.setSupportForm();
  },
};
</script>

<style scoped>
.couple-page-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: border-box;
}
.about-text {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
</style>
