<template>
  <!-- eslint-disable max-len -->
  <div class="card mb-5 shadow-sm">
    <table class="table table-borderless table-hover table-responsive-md">
      <thead class="bg-light">
        <tr>
          <th class="text-uppercase text-sm"></th>
          <th class="text-uppercase text-sm">Couple</th>
          <th class="text-uppercase text-sm">Wedding Date</th>
          <th class="text-uppercase text-sm">Attendence</th>
          <th class="text-uppercase text-sm"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(e,i) in EInvites" :key="i">
          <th class="align-middle"><img :src="e.couple_img_url" class="budget-img"/></th>
          <td class="align-middle">{{i+1}}. {{e.couple}}</td>
          <td class="align-middle">{{e.date}}</td>
          <td class="align-middle"><span class="badge text-uppercase p-2" :class="e.attendance_status === 'Livestream' ? 'badge-info' : (e.attendance_status === 'Reception'&& e.attendance_status ? 'badge-success' : 'badge-warning')">{{e.attendance_status ? e.attendance_status:'tbd'}}</span></td>
          <td class="align-middle"><a href="#" class="btn btn-outline-dark btn-sm shadow-sm" @click="confirmAttendace(e,i)">View</a></td>
        </tr>
      </tbody>
      <tbody v-if=" EInvites.length === 0">
        <td class="align-middle text-muted">No invites yet</td>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GuestlistComponent',
  props: {
  },
  data: () => ({
  }),
  computed: {
    ...mapState(['EInvites']),
  },
  methods: {
    confirmAttendace(e, i) {
      console.log(e, i);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
