<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'app',
  components: {
    Header,
    Footer,
  },
};

</script>

<style>
/* #nprogress {
  pointer-events: auto !important;
} */
:root {
  /* --profile_card_radius: 12px; */
  --profile_card_radius: 0px;
  --primary: rgba(255,82,82, 1) !important;
}
#nprogress .bar {
  background:rgba(255,82,82, 0.8) !important;
  height: 3px !important;
}
#nprogress .peg {
    box-shadow: 0 0 10px rgba(255,82,82, 1), 0 0 5px rgba(255,82,82, 1) !important;
}
#nprogress .spinner-icon {
  border-top-color: rgba(255,82,82, 0) !important;
  border-left-color: rgba(255,82,82, 0) !important;
}
.budget-img {
  background: #fff;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0;
  padding-right: 0;
  border-radius: 50%;
  object-fit: cover;
}
.border-edges {
  border-radius: 0.5rem !important;
}
.profile-img {
  background: #fff;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.vendor-card-img {
  /* border-radius: 4px !important; */
  border-radius: 1px !important;
  overflow: hidden;
}
.offering-card-img {
  object-fit: cover;
  height: 12rem;
}
button, input, select, textarea, table, .btn, .btn-sm, .card, .block-header,
.dropdown-menu, .badge, .pagination, .page-item, li, .rounded-iwh {
  /* border-radius: 4px !important; */
  border-radius: 0px !important;
}
.customer-profile, .launch-card-radius, .profile-card-top {
  border-top-left-radius: var(--profile_card_radius) !important;
  border-top-right-radius: var(--profile_card_radius) !important;
}
.launch-card-radius, .profile-card-bottom {
  border-bottom-left-radius: var(--profile_card_radius) !important;
  border-bottom-right-radius: var(--profile_card_radius) !important;
}
.launch-card-radius {
  overflow: hidden;
}
.card {
  overflow: hidden;
}
.badge-warning {
  color: #fff !important;
  background-color: #ffc107 !important;
}
/* .btn-warning {
  color: #fff !important;
  background-color: #feee80 !important;
}
.btn-warning:hover, .btn-warning:focus {
  color: #fff !important;
  background-color: #feeb67 !important;
} */
.text-nowrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.upcoming-card{
  object-fit: cover;
  height: 5.4rem;
  max-width: 100%;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
}
@media (max-width: 573px) {
  .upcoming-card{
    height: 18rem;
  }
}
@media (min-width: 574px) and (max-width: 862px) {
  .upcoming-card{
    height: 12rem;
  }
}
@media (min-width: 863px) and (max-width: 1199px) {
  .upcoming-card{
    height: 18rem;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
