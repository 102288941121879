<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="hero-content text-center mb-3">
      <h3 class="hero-heading pb-1">Launch Wedding</h3>
    </div>
    <div class="d-flex justify-content-center w-100" v-if="!edit&&launch_card_url">
      <div class="launch-card-radius mb-3 w-card text-center shadow-sm">
        <img class="card-img-top shadow-sm" :src="launch_card_img_url" alt="Launch Card Image">
        <div class="card-body">
          <h4 class="card-title">About Us</h4>
          <p class="card-text">{{story}}</p>
          <button class="btn btn-light" @click="editLaunchDetails"><i class="fas fa-edit text-dark mr-1"></i>Edit</button>
          <button class="btn btn-dark ml-1" @click="showLaunchModel = true"><i class="fas fa-share mr-1"></i>Share</button>
        </div>
      </div>
    </div>
    <div v-if="edit || !launch_card_url">
      <h5 class="text-uppercase mb-3" style="font-size: 0.9em;">Our Card</h5>
      <p class="mb-4">Upload <span v-if="launch_card_url">new</span> save-the-date card or wedding launch card.</p>
      <div class="text-center mb-4">
        <div>
          <img v-if="launch_card_img_url" class="launch-card-radius mx-50 mb-4 w-card shadow-sm" :src="launch_card_url">
        </div>
        <button v-if="!change_launch_card && launch_card_url" @click="change_launch_card = true" class="btn btn-outline-dark mr-3" type="button" name="button"><i class="far fa-edit fa-lg"></i>Change Profile Image</button>
        <div v-if="change_launch_card || !launch_card_url">
          <div>
            <input type="file" ref="launchcard" name="launchcard" class="btn btn-light border-md mx-auto mb-4" style="opacity: 1;" @change="onCardSelected">
          </div>
          <button class="btn btn-outline-dark mr-3" type="button" name="button" @click="uploadLaunchCard"><i class="fas fa-upload fa-lg"></i> Upload <span v-if="launch_card_url">New</span> Card</button>
        </div>
      </div>
      <!-- <hr class="my-4"> -->
      <h5 class="text-uppercase mb-3 mt-4" style="font-size: 0.9em;">About Us / Our Story</h5>
      <p class="mb-3">Add a short story of you two and launch your wedding.</p>
      <form @submit.prevent="addStory">
        <div class="form-group">
          <textarea name="name" rows="6" class="form-control w-100 p-2" v-model="our_story" placeholder="Add your story.."></textarea>
        </div>
        <div class="my-4 d-flex justify-content-center mx-auto">
          <button class="btn btn-dark shadow-sm mr-1" @click="cancelLaunchUpdate" v-if="launch_card_url">&#x2715; Cancel</button>
          <button class="btn btn-outline-dark shadow-sm" type="submit" name="button">Save <i class="fas fa-save fa-lg"></i></button>
        </div>
      </form>
    </div>
    <!-- launch card qr-code model -->
    <div class="modal fade" :class="showLaunchModel ? 'show':''" :style="showLaunchModel ? 'display: block;':'display: none;'">
      <div class="w-100 h-100 bg-dark position-fixed" style="opacity: 0.33; z-index: 1051; bottom: 0; right: 0; top: 0; left: 0;" :style="showLaunchModel ? 'display: block;':'display: none;'" @click="showLaunchModel = false"></div>
      <div class="modal-dialog h-100 d-flex flex-column justify-content-around" style="position: fixed; z-index: 1055; left: 0; right: 0;">
        <div class="modal-content">
          <button type="button" @click="showLaunchModel = false" class="close modal-close">
            <svg viewBox="0 0 64 64" class="svg-icon w-100 h-100 svg-icon-light align-middle"><path fill="none" stroke-miterlimit="10" d="M41.999 20.002l-22 22m22 0L20 20" stroke-linejoin="round" stroke-linecap="round"/></svg>
          </button>
          <div class="py-4">
            <h2 class="modal-title text-center">Couple QR Code</h2>
            <!-- <vue-qrcode :value="coupleUrl" /> -->
            <div class="d-flex flex-column justify-content-center align-items-center">
              <qrcode-vue :value="coupleUrl" :size="200" margin="1" level="H" class="my-4 p-4 bg-light" />
              <div class="d-flex justify-content-center align-items-center">
                <span class="text-dark mr-2 font-weight-bold">url:</span>
                <code class="bg-light px-3 py-2 text-dark"><span :style="copy_btn_text==='copied'?'background-color: #007bff; !important; color: #fff':''">{{coupleUrl}}</span></code>
                <button type="button" @click="copyUrl" class="btn btn-sm border-left" :class="copy_btn_text==='copied'?'btn-dark':'btn-light'" style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important;">{{copy_btn_text}}</button>
              </div>
              <p class="my-4"><span class="text-muted font-weight-light">----------------------</span><span class="mx-2 font-weight-bold text-muted">Share</span><span class="text-muted font-weight-light">-------------------------</span></p>
              <div class="d-flex justify-content-center align-items-center mb-2 p-2 bg-light">
                <!-- <button type="button" class="btn btn-info rounded-circle">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"><path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/></svg>
                </button> -->
                <button type="button" class="btn btn-whatsapp rounded-circle mx-1"  style="padding-bottom: 0.7rem;">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>
                </button>
                <button type="button" class="btn btn-twitter rounded-circle mx-1" style="padding-bottom: 0.7rem;">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"><path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/></svg>
                </button>
                <button type="button" class="btn btn-facebook rounded-circle mx-1"  style="padding-bottom: 0.7rem;">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg>
                </button>
                <button type="button" class="btn btn-instagram rounded-circle mx-1"  style="padding-bottom: 0.7rem;">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/></svg>
                </button>
                <button type="button" class="btn btn-reddit rounded-circle mx-1"  style="padding-bottom: 0.7rem;">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-reddit" viewBox="0 0 16 16"><path d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z"/><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353 0 1.795 2.091 3.256 4.669 3.256 2.577 0 4.668-1.451 4.668-3.256 0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165z"/></svg>
                </button>
                <button type="button" class="btn btn-warning rounded-circle mx-1 text-white"  style="padding-bottom: 0.7rem;">
                  <svg width="16" height="16" fill="currentColor" class="bi bi-download"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
// import VueQrcode from 'vue-qrcode';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Launchwedding',
  components: {
    // VueQrcode,
    QrcodeVue,
  },
  props: {
  },
  data: () => ({
    launch_card_url: 'https://images.unsplash.com/photo-1501005727661-77318235fcac?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=751&q=80',
    // launch_card_url: '',
    upload_launch_card: null,
    our_story: '',
    edit: false,
    change_launch_card: false,
    showLaunchModel: false,
    copy_btn_text: 'copy',
  }),
  created() {
    this.our_story = this.story;
    this.launch_card_url = this.launch_card_img_url;
  },
  mounted() {
    this.launch_card_url = this.launch_card_img_url;
  },
  updated() {
    this.launch_card_url = this.launch_card_img_url;
  },
  methods: {
    onCardSelected(e) {
      console.log(e.target.files);
      /* eslint-disable prefer-destructuring */
      this.upload_launch_card = e.target.files[0];
      // console.log(this.upload_launch_card);
    },
    uploadLaunchCard() {
      const token = localStorage.getItem('_ikn');
      const id = localStorage.getItem('_ud');
      const fb = new FormData();
      fb.append('launchcard', this.upload_launch_card);
      axios.put(`${this.surl}/api/users/couples/${id}/update/launch`, fb, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          const account = 'Couple';
          this.$store.dispatch('setCurrentUser', { account });
          this.edit = false;
          this.change_launch_card = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    addStory(e) {
      e.preventDefault();
      const token = localStorage.getItem('_ikn');
      const id = localStorage.getItem('_ud');
      console.log(this.our_story);
      axios.put(`${this.surl}/api/users/couples/${id}/update/launch`, {
        story: this.our_story,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response.data);
          const account = 'Couple';
          this.$store.dispatch('setCurrentUser', { account });
          this.edit = false;
          this.change_launch_card = false;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    editLaunchDetails() {
      this.edit = true;
      this.$store.dispatch('smoothScroll');
    },
    cancelLaunchUpdate() {
      this.edit = false;
      this.change_launch_card = false;
      this.$store.dispatch('smoothScroll');
    },
    copyUrl() {
      navigator.clipboard.writeText(this.coupleUrl);
      this.copy_btn_text = 'copied';
      setTimeout(() => {
        this.copy_btn_text = 'copy';
      }, 3000);
    },
  },
  computed: {
    ...mapGetters(['launch_card_img_url', 'story', 'user', 'surl']),
    coupleUrl() {
      return `http://localhost:8080/couples/${this.user.username}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .bi {
  width: 1rem !important;
  height: 1rem !important;
} */
.model {
  z-index: 1055 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.w-card {
  width: 24rem !important;
}
@media (max-width: 768px) {
  .w-card {
    width:100% !important;
  }
}
.btn-whatsapp {
  color: #fff;
  background-color:#25D366;
  border-color:#25D366;
}
.btn-whatsapp:hover {
  color: #fff;
  background-color: #21bd5c;
  border-color: #21bd5c;
}
.btn-whatsapp:focus {
  color: #fff;
  background-color: #21bd5c;
  border-color: #21bd5c;
}
.btn-twitter {
  color: #fff;
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}
.btn-twitter:hover {
  color: #fff;
  background-color: #0d95e8;
  border-color: #0d95e8;
}
.btn-twitter:focus {
  color: #fff;
  background-color: #0d95e8;
  border-color: #0d95e8;
}
.btn-facebook {
  color: #fff;
  background-color: #4267B2;
  border-color: #4267B2;
  /* background-color: #1877F2;
  border-color: #1877F2; */
}
.btn-facebook:hover {
  color: #fff;
  background-color: #3b5c9f;
  border-color: #3b5c9f;
  /* background-color: #0d6ae4;
  border-color: #0d6ae4; */
}
.btn-facebook:focus {
  color: #fff;
  background-color: #3b5c9f;
  border-color: #3b5c9f;
  /* background-color: #0d6ae4;
  border-color: #0d6ae4; */
}
.btn-instagram {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}
.btn-instagram:hover {
  color: #fff;
  background-color: #191919;
  border-color: #191919;
}
.btn-instagram:focus {
  color: #fff;
  background-color: #191919;
  border-color: #191919;
}
.btn-reddit {
  color: #fff;
  background-color: #FF5700;
  border-color: #FF5700;
}
.btn-reddit:hover {
  color: #fff;
  background-color: #FF4500;
  border-color: #FF4500;
}
.btn-reddit:focus {
  color: #fff;
  background-color: #FF4500;
  border-color: #FF4500;
}
</style>
